import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { useSnackbar } from '../../components/SnackbarProvider';
import {
    handleApiError,
    DisableDialog,
    Accordion,
    UploadDocumentButton,
    DownloadDocumentButton,
    formatDate,
    formatUnit
} from '../../components/Utils';
import {
    TextField,
    Button,
    Typography,
    Paper,
    Grid,
    Box,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';


function TransporterDetails() {
    const { id } = useParams();
    const { api } = useAuth();
    const showSnackbar = useSnackbar();
    const [loading, setLoading] = useState({ general: true, update: false });
    const [error, setError] = useState({});

    const [openDisableDialog, setOpenDisableDialog] = useState(false);
    const [disableId, setDisableId] = useState(null);
    const [navigateToWhenDisabled, setNavigateToWhenDisabled] = useState(false);
    const [endpointToDisable, setEndpointToDisable] = useState(false);

    const [deliveryNotes, setDeliveryNotes] = useState({});

    const [transporterForm, setTransporterForm] = useState({});
    const [trucks, setTrucks] = useState({});

    const fetchTransporter = useCallback(async () => {
        try {
            setLoading(prev => ({ ...prev, general: true }));
            const result = await api().get(`/transporters/${id}`);
            const data = result.data;
            const transporter = result.data.transporter;

            setTransporterForm(transporter);
            setDeliveryNotes(data.delivery_notes);
            setTrucks(transporter.trucks);

        } catch (exception_error) {
            setError(prevError => ({ ...prevError, fetch: "Error obteniendo operador de transporte" }));

        } finally {
            setLoading(prev => ({ ...prev, general: false }));
        }
    }, [api, id]);

    useEffect(() => {
        fetchTransporter();
    }, []);

    const handleChangeForm = (e) => {
        const { name, value } = e.target;
        setTransporterForm(prevTransporterForm => ({ ...prevTransporterForm, [name]: value }));
    };


    const validateForm = () => {
        const errors = {};

        if (!transporterForm.name) {
            errors.name = "Por favor, añada un nombre.";
        } else if (transporterForm.name.length > 36) {
            errors.name = "El nombre no debe exceder los 36 caracteres.";
        }
        if (transporterForm.email && transporterForm.email.length > 36) {
            errors.email = "El email no debe exceder los 36 caracteres.";
        }
        if (transporterForm.phone && transporterForm.phone.length > 36) {
            errors.phone = "El teléfono no debe exceder los 36 caracteres.";
        }
        if (transporterForm.country && transporterForm.country.length > 36) {
            errors.country = "El país no debe exceder los 36 caracteres.";
        }
        if (transporterForm.zip_code && transporterForm.zip_code.length > 40) {
            errors.zip_code = "El código postal no debe exceder los 40 caracteres.";
        }
        if (transporterForm.region && transporterForm.region.length > 150) {
            errors.region = "La localidad no debe exceder los 150 caracteres.";
        }
        if (transporterForm.city && transporterForm.city.length > 150) {
            errors.city = "La ciudad no debe exceder los 150 caracteres.";
        }
        if (transporterForm.commercial_name && transporterForm.commercial_name.length > 50) {
            errors.commercial_name = "El alias no debe exceder los 50 caracteres.";
        }
        if ((transporterForm.doc_type && !transporterForm.documentation) || (!transporterForm.doc_type && transporterForm.documentation)) {
            errors.documentation = "Si quiere poner una documentación, debe rellenar ambos campos (tipo y documentación).";
        }
        if (transporterForm.documentation && transporterForm.documentation.length > 15) {
            errors.documentation = "La documentación no debe exceder los 15 caracteres.";
        }

        return errors;
    };

    const handleUpdate = async (e) => {

        let error = null;

        try {
            e.preventDefault();
            setLoading(prev => ({ ...prev, update: true }));


            const validationError = validateForm();
            setError(validationError);

            const result = await api().patch(`/transporters/`, transporterForm);

            setTransporterForm(result.data);
            showSnackbar('Transportista actualizado correctamente');

        } catch (e) {
            error = handleApiError(e, 'Error inesperado actualizando operador de transporte.')

        } finally {
            setError(prevError => ({ ...prevError, update: error }));
            setLoading(prev => ({ ...prev, update: false }));
        }
    };

    const handleOpenDisableDialog = (itemId, endpoint, navigateTo) => {
        setDisableId(itemId);
        setEndpointToDisable(endpoint);
        setNavigateToWhenDisabled(navigateTo);
        setOpenDisableDialog(true);
    };

    return (
        <div style={{ padding: 20 }}>
            {loading?.general && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading?.general && (
                <div>
                    <Grid container spacing={3} style={{ position: 'fixed', top: 60, right: 10, zIndex: 1100, width: 'auto' }}>
                        <Grid item>
                            <Paper elevation={0} style={{ padding: '10px', textAlign: 'center', backgroundColor: '#fff' }}>
                                <Box mb={2}>
                                    <Typography variant="h5" component="h1" gutterBottom>
                                        {transporterForm.code} - {transporterForm.name}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={10} lg={8}>

                        {/* Transporter Accordion */}
                        <Accordion title={`${transporterForm.code} ${transporterForm.name}`}>
                            <form onSubmit={handleUpdate} style={{ padding: '20px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Nombre"
                                            name="name"
                                            value={transporterForm.name}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 36 }}
                                            error={!!error?.name}
                                            helperText={error?.name}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            name="email"
                                            value={transporterForm.email}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 36 }}
                                            error={!!error?.email}
                                            helperText={error?.email}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Teléfono"
                                            name="phone"
                                            value={transporterForm.phone}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 36 }}
                                            error={!!error?.phone}
                                            helperText={error?.phone}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Nombre comercial (alias)"
                                            name="commercial_name"
                                            value={transporterForm.commercial_name}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 50 }}
                                            error={!!error?.commercial_name}
                                            helperText={error?.commercial_name}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <FormControl
                                            style={{ width: "100%" }}
                                        >
                                            <InputLabel id="document-type-label">
                                                Documentación
                                            </InputLabel>
                                            <Select
                                                labelId="document-type-label"
                                                id="document-type"
                                                label='Tipo documentación'
                                                value={transporterForm.doc_type}
                                                onChange={handleChangeForm}
                                                name="doc_type"
                                                inputProps={{ id: 'document-type' }}
                                                error={!!error?.doc_type}
                                                helperText={error?.doc_type}
                                            >
                                                <MenuItem value="DNI">DNI</MenuItem>
                                                <MenuItem value="NIE">NIE</MenuItem>
                                                <MenuItem value="CIF">CIF</MenuItem>
                                                <MenuItem value="OTRO">OTRO</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Documentación"
                                            name="documentation"
                                            value={transporterForm.documentation}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 15 }}
                                            error={!!error?.documentation}
                                            helperText={error?.documentation}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="País"
                                            name="country"
                                            value={transporterForm.country}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 36 }}
                                            error={!!error?.country}
                                            helperText={error?.country}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Localidad"
                                            name="region"
                                            value={transporterForm.region}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 150 }}
                                            error={!!error?.region}
                                            helperText={error?.region}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Ciudad"
                                            name="city"
                                            value={transporterForm.city}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 150 }}
                                            error={!!error?.city}
                                            helperText={error?.city}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Código postal"
                                            name="zip_code"
                                            value={transporterForm.zip_code}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 40 }}
                                            error={!!error?.zip_code}
                                            helperText={error?.zip_code}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={12}>
                                        <TextField
                                            fullWidth
                                            label="Dirección"
                                            name="address"
                                            value={transporterForm.address}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 100 }}
                                            error={!!error?.phone}
                                            helperText={error?.phone}
                                        />
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleOpenDisableDialog(id, '/transporters/disable', '/transporters/list')}
                                            fullWidth
                                            style={{ backgroundColor: 'red' }}>
                                            Eliminar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={loading?.update}
                                            fullWidth>
                                            {loading?.update ? (
                                                <CircularProgress size={24} color="inherit" />
                                            ) : (
                                                'Actualizar'
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                                {error.update && (
                                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                        {error.update}
                                    </Typography>
                                )}
                            </form>
                        </Accordion>

                        {/* Documents Accordion */}
                        <Accordion title="Documentos">
                            <UploadDocumentButton
                                to={'transporter_id'}
                                id={id}
                                fetch={fetchTransporter}>
                            </UploadDocumentButton>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nombre</TableCell>
                                            <TableCell>Acciones</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {transporterForm.documents.filter(item => !item.disabled).map(item => (
                                            <TableRow key={item.id}>
                                                <TableCell>
                                                    <DownloadDocumentButton documentId={item.id} documentName={item.name} />
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="outlined"
                                                        color="secondary"
                                                        onClick={() => handleOpenDisableDialog(item.id, '/documents/disable')}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Accordion>

                        {/* Trucks Accordion */}
                        <Accordion title="Camiones">
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Código</TableCell>
                                            <TableCell>Placa</TableCell>
                                            <TableCell>Caducidad</TableCell>
                                            <TableCell>Tara</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {trucks?.filter(truck => !truck.disabled).map(truck => (
                                            <TableRow key={truck.id}>
                                                <TableCell>
                                                    <Link to={`/trucks/${truck.id}/details`}>
                                                        {truck.code}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>{truck.license_plate}</TableCell>
                                                <TableCell>{formatDate(truck.expiration_date)}</TableCell>
                                                <TableCell>{truck.tare} kg</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Accordion>

                        {/* Delivery Notes Accordion */}
                        <Accordion title="Albaranes">
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Código</TableCell>
                                            <TableCell>Fecha de Creación</TableCell>
                                            <TableCell>Neto</TableCell>
                                            <TableCell>Grupo de Producto</TableCell>
                                            <TableCell>Placa del Camión</TableCell>
                                            <TableCell>Nombre del Conductor</TableCell>
                                            <TableCell>Acciones</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {deliveryNotes?.filter(del_note => !del_note.disabled).map(del_note => (
                                            <TableRow key={del_note.id}>
                                                <TableCell>
                                                    <Link to={`/delivery_notes/${del_note.id}/details`}>
                                                        {del_note.code}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>{formatDate(del_note.date_created)}</TableCell>
                                                <TableCell>{del_note.net} {formatUnit(del_note.product.product_group.unit)}</TableCell>
                                                <TableCell>
                                                    <Link to={`/products/${del_note.product.product_group.id}/details`}>
                                                        {del_note.product.product_group.name}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Link to={`/trucks/${del_note.truck.id}/details`}>
                                                        {del_note.truck.license_plate || "-"}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Link to={`/truck_drivers/${del_note.truck_driver.id}/details`}>
                                                        {del_note.truck_driver.name || "-"}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="outlined"
                                                        color="secondary"
                                                        onClick={() => handleOpenDisableDialog(del_note.id, '/delivery_notes/disable')}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Accordion>

                        <DisableDialog
                            open={openDisableDialog}
                            onClose={() => setOpenDisableDialog(false)}
                            endpoint={endpointToDisable}
                            disableId={disableId}
                            navigateTo={navigateToWhenDisabled}
                            fetch={fetchTransporter}
                        />
                    </Grid>
                </div>)
            }
        </div >
    );
}

export default TransporterDetails;
