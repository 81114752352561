import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { useSnackbar } from '../../components/SnackbarProvider';
import AddLineModal from '../../components/NewOfferOrderLine';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    CircularProgress,
    Typography,
    Button,
    Card,
    CardContent,
    CardHeader,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    Grid,
    TableContainer,
    Table,
    Paper,
    Alert,
    TextField,
    IconButton,
    Box,
    Autocomplete
} from '@mui/material';
import {
    formatDate,
    formatPrice,
    formatTime,
    formatOfferStatus,
    CreatePdf,
    handleApiError,
    DisableDialog,
    DecimalTextField,
    formatAutocompleteOption
} from '../../components/Utils';
import DeleteIcon from '@mui/icons-material/Delete';

function OfferDetails() {
    const { id } = useParams();
    const { api } = useAuth();
    const showSnackbar = useSnackbar();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [buildings, setBuildings] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState(null);

    const [productGroups, setProductGroups] = useState([]);
    const [addLineModal, setAddLineModal] = useState(false);
    const [deleteLineModal, setDeleteLineModal] = useState(false);
    const [selectedDetailId, setSelectedDetailId] = useState(null);

    const [offer, setOffer] = useState(null);
    const [referencedBuilding, setReferencedBuilding] = useState(null);
    const [statusUpdateError, setStatusUpdateError] = useState(null);

    const [openDisableDialog, setOpenDisableDialog] = useState(false);
    const [disableId, setDisableId] = useState(null);
    const [navigateToWhenDisabled, setNavigateToWhenDisabled] = useState(false);
    const [endpointToDisable, setEndpointToDisable] = useState(false);

    const fetchOffer = useCallback(async () => {
        try {
            const result = await api().get(`/offers/${id}`);
            setOffer(result.data);
            setSelectedBuilding(result.data.building);
            setReferencedBuilding(result.data.referenced_building);
            setLoading(false);
        } catch (error) {
            setError("Error obteniendo oferta");
            setLoading(false);
        }
    }, [api, id]);

    const fetchProductGroups = useCallback(async () => {
        try {
            setLoading(true);
            const result = await api().get("/products/");

            const filteredProductGroups = result.data[0].filter(
                (productGroup) => productGroup.products.length > 0
            );

            setProductGroups(filteredProductGroups);
        } catch (error) {
            setError({ general: "Error obteniendo los grupos de productos" });
        } finally {
            setLoading(false);
        }
    }, [api]);

    const fetchBuildings = useCallback(async () => {
        try {
            setLoading(true);
            const result = await api().get("/buildings/");
            setBuildings(result.data[0]);
        } catch (error) {
            setError({ general: "Error obteniendo las obras" });
        } finally {
            setLoading(false);
        }
    }, [api]);

    const handleAcceptOffer = async () => {
        let error = null;
        try {
            setLoading(true);
            const result = await api().post(`/offers/${id}/accept`);
            setOffer(result.data);
            showSnackbar('Oferta aceptada correctamente');
        } catch (e) {
            error = handleApiError(e, 'Error inesperado aceptando oferta.');
        } finally {
            if (error) showSnackbar(error, 'error');
            setLoading(false);
        }
    };

    const handleDeclineOffer = async () => {
        let error = null;
        try {
            setLoading(true);
            const result = await api().post(`/offers/${id}/decline`);
            setOffer(result.data);
            showSnackbar('Oferta rechazada correctamente');
        } catch (e) {
            error = handleApiError(e, 'Error inesperado rechazando oferta.');
        } finally {
            if (error) showSnackbar(error, 'error');
            setLoading(false);
        }
    };

    const handleDeleteOfferLine = async (lineId) => {
        let error = null;
        try {
            setLoading(true);
            const result = await api().patch(`/offers/remove_offer_detail`, { offer_detail_id: lineId });
            setOffer(result.data);
            showSnackbar('Línea eliminada correctamente');
        } catch (e) {
            error = handleApiError(e, 'Error inesperado eliminando línea.');
        } finally {
            if (error) showSnackbar(error, 'error');
            setLoading(false);
        }
    };

    const handleUpdate = async () => {
        let error = null;
        try {
            const payload = {
                offer_id: offer.id,
                building_id: selectedBuilding?.id || null,
                referenced_building: referencedBuilding || null,
            };
            const result = await api().patch('/offers/', payload);
            setOffer(result.data);
            setSelectedBuilding(result.data.building);
            showSnackbar('Oferta actualizada correctamente');
        } catch (e) {
            error = handleApiError(e, 'Error inesperado actualizando oferta.');
        } finally {
            if (error) showSnackbar(error, 'error');
            setLoading(false);
        }
    };

    const handleOfferDetailChange = (event, name, detailId) => {
        const newValue = event.target.value;

        setOffer((prevOffer) => ({
            ...prevOffer,
            offer_details: prevOffer.offer_details.map((detail) =>
                detail.id === detailId ? { ...detail, [name]: newValue } : detail
            )
        }));
    };

    const updateOfferDetails = async () => {
        let error = null;

        try {
            setLoading(true);

            const updatedPrices = offer.offer_details.map((detail) => ({
                id: detail.id,
                quantity: parseFloat(detail.quantity),
                price: parseFloat(detail.unit_price) // Asegura que el precio sea un número flotante
            }));

            await api().patch(`/offers/update_offer_detail`, updatedPrices);
            showSnackbar(`Oferta actualizada correctamente.`);
            fetchOffer();

        } catch (e) {
            error = handleApiError(e, 'Error inesperado al actualizar oferta.');
        } finally {
            if (error) showSnackbar(error, 'error');
            setLoading(false);
        }
    };

    const handleOfferSubmitSuccess = (data) => {
        setOffer(data);
        setAddLineModal(false);
    };

    useEffect(() => {
        fetchOffer();
        fetchBuildings();
        fetchProductGroups();
    }, []);

    const handleOpenDisableDialog = (itemId, endpoint, navigateTo) => {
        setDisableId(itemId);
        setEndpointToDisable(endpoint);
        setNavigateToWhenDisabled(navigateTo);
        setOpenDisableDialog(true);
    };

    const handleOpenDeleteDialog = (id) => {
        setSelectedDetailId(id);
        setDeleteLineModal(true);
    };

    const handleCloseDialog = () => {
        setDeleteLineModal(false);
        setSelectedDetailId(null);
    };

    const handleConfirmDelete = () => {
        handleDeleteOfferLine(selectedDetailId);
        handleCloseDialog();
    };

    const canUpdateOffer = offer?.offer_status === 'pending';

    return (
        <div style={{ padding: 20 }}>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    {/* Mostrar el mensaje de error si existe */}
                    {error && (
                        <Alert severity="error" style={{ marginBottom: '20px' }}>
                            {error}
                        </Alert>
                    )}
                    {/* Mostrar el mensaje de error al marcar como recibido */}
                    {statusUpdateError && (
                        <Alert severity="error" style={{ marginBottom: '20px' }}>
                            {statusUpdateError}
                        </Alert>
                    )}

                    <Card sx={{ margin: 'auto' }}>
                        <CardHeader
                            title={(
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', padding: '20px 0' }}>
                                    <span style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                                        {`Oferta: ${offer?.code} | Fecha: ${formatDate(offer?.offer_date) || '-'} ${formatTime(offer?.offer_date) || '-'}`}
                                    </span>

                                    <Grid item xs={2}
                                        style={{
                                            position: 'absolute',
                                            right: '16px',
                                        }}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleOpenDisableDialog(id, '/offers/disable', '/offers/list')}
                                            style={{ backgroundColor: 'red', marginRight: '16px' }} // Margen derecho para separación
                                        >
                                            Eliminar
                                        </Button>
                                    </Grid>
                                </div>
                            )}
                            sx={{
                                backgroundColor: '#f5f5f5',
                                padding: '16px 0'
                            }}
                        />

                        {/* Diálogo de confirmación de eliminación */}
                        <DisableDialog
                            open={openDisableDialog}
                            onClose={() => setOpenDisableDialog(false)}
                            endpoint={endpointToDisable}
                            disableId={disableId}
                            navigateTo={navigateToWhenDisabled}
                        />
                        <CardContent>
                            <Grid container spacing={3}>
                                {/* Información del Cliente */}
                                <Grid item xs={12} md={9}>
                                    <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', marginBottom: '20px', textAlign: 'left' }}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={3}>
                                                <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', fontSize: '16px' }}>
                                                    Información del Cliente
                                                </Typography>
                                                <Typography variant="body2" sx={{ fontSize: '14px', marginBottom: '10px' }}>
                                                    <strong>Código: </strong>
                                                    <Link to={`/clients/${offer?.client_id}/details`}>
                                                        {offer?.client.code || '-'} <br />
                                                    </Link>
                                                    <strong>Nombre: </strong> {offer?.client.name || '-'} <br />
                                                    <strong>Email: </strong> {offer?.client.email || '-'} <br />
                                                    <strong>Teléfono: </strong> {offer?.client.phone || '-'} <br />
                                                    <strong>Documentación: </strong> {offer?.client.documentation || '-'} <br />
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', fontSize: '16px' }}>
                                                    Información de la obra
                                                </Typography>
                                                {!canUpdateOffer ? (
                                                    offer?.building ? (
                                                        <Typography variant="body2" sx={{ fontSize: '14px', marginBottom: '10px' }}>
                                                            <strong>Código: </strong>
                                                            <Link to={`/buildings/${offer?.building_id}/details`}>
                                                                {offer?.building.code || '-'} <br />
                                                            </Link>
                                                            <strong>Nombre: </strong> {offer?.building.name || '-'} <br />
                                                        </Typography>
                                                    ) : (
                                                        <Typography variant="body2" sx={{ fontSize: '14px', marginBottom: '10px' }}>
                                                            Sin obra asociada
                                                        </Typography>
                                                    )
                                                ) : (
                                                    <Autocomplete
                                                        options={buildings}
                                                        getOptionLabel={(option) => formatAutocompleteOption(option)}
                                                        value={selectedBuilding}
                                                        onChange={(event, newValue) => setSelectedBuilding(newValue)}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Obra" fullWidth error={!!error?.building} helperText={error?.building} />
                                                        )}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', fontSize: '16px' }}>
                                                    Obra Referenciada
                                                </Typography>
                                                {canUpdateOffer ? (
                                                    <>
                                                        <TextField
                                                            label="Obra referenciada"
                                                            fullWidth
                                                            value={referencedBuilding}
                                                            onChange={(e) => setReferencedBuilding(e.target.value)}
                                                        />
                                                        <Box display="flex" justifyContent="flex-end" sx={{ marginTop: '10px' }}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => handleUpdate()}
                                                            >
                                                                Actualizar
                                                            </Button>
                                                        </Box>
                                                    </>
                                                ) : (
                                                    <Typography variant="body2" sx={{
                                                        fontSize: '14px', marginBottom: '10px'
                                                    }}>
                                                        {referencedBuilding || '-'}
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', marginBottom: '20px' }}>
                                        <Grid container spacing={3}>
                                            {/* Estado de la oferta */}
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>
                                                    Estado de la oferta
                                                </Typography>
                                                <Typography variant="body2" sx={{ fontSize: '14px', marginBottom: '30px', textAlign: 'center' }}>
                                                    {formatOfferStatus(offer?.offer_status) || '-'}
                                                </Typography>

                                                {offer?.order ? (
                                                    <>
                                                        <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>
                                                            Pedido:
                                                        </Typography>
                                                        <Typography variant="body2" sx={{ fontSize: '14px', marginBottom: '19px', textAlign: 'center' }}>
                                                            <Link to={`/orders/${offer.order.id}/details`}>
                                                                {offer.order.code}
                                                            </Link>
                                                        </Typography>
                                                    </>
                                                ) : (
                                                    // Mostrar los botones de aceptar y rechazar si el estado es "pending"
                                                    offer?.offer_status === "pending" && (
                                                        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                                                            <Button variant="contained" color="success" onClick={handleAcceptOffer}>
                                                                Aceptar
                                                            </Button>
                                                            <Button variant="contained" color="error" onClick={handleDeclineOffer}>
                                                                Rechazar
                                                            </Button>
                                                        </div>
                                                    )
                                                )}
                                            </Grid>

                                            {/* Generar PDF */}
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>
                                                    Generar PDF
                                                </Typography>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <CreatePdf endpoint={`/offers/${id}/generate-pdf`}>
                                                        <Button variant="outlined" color="secondary" sx={{ width: '100%' }}>
                                                            Crear PDF
                                                        </Button>
                                                    </CreatePdf>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>

                            {/* Tabla de productos de la oferta */}
                            <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', bofferRadius: '8px', marginTop: '20px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                                    <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                                        <Typography
                                            variant="h6"
                                            sx={{ fontWeight: 'bold', fontSize: '16px' }}
                                        >
                                            Oferta: {offer?.code || '-'}
                                        </Typography>
                                    </Box>
                                    {canUpdateOffer && (
                                        <>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center',
                                                gap: 1
                                            }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => setAddLineModal(true)}
                                                    disabled={loading}
                                                >
                                                    Añadir línea
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={updateOfferDetails}
                                                    disabled={loading}
                                                >
                                                    Actualizar
                                                </Button>
                                            </Box>

                                        </>
                                    )}
                                </Box>
                                <AddLineModal
                                    open={addLineModal}
                                    id={id}
                                    isOffer={true}
                                    onClose={() => setAddLineModal(false)}
                                    productGroups={productGroups}
                                    onSubmitSuccess={handleOfferSubmitSuccess}
                                />
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><strong>Producto</strong></TableCell>
                                                <TableCell><strong>Cantera</strong></TableCell>
                                                <TableCell><strong>Cantidad</strong></TableCell>
                                                <TableCell><strong>Precio Unitario</strong></TableCell>
                                                <TableCell><strong>Total</strong></TableCell>
                                                {canUpdateOffer && <TableCell><strong>Eliminar</strong></TableCell>}                                            
                                                </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {offer?.offer_details.map((detail) => (
                                                <TableRow key={detail.id}>
                                                    <TableCell>{detail.product_name || '-'}</TableCell>
                                                    <TableCell>{detail.product.quarry.name || '-'}</TableCell>
                                                    {canUpdateOffer ? (
                                                        <>
                                                            <TableCell style={{ display: 'flex', alignItems: 'center' }}>
                                                                <DecimalTextField
                                                                    label={`Cantidad (${detail.unit})`}
                                                                    name={`quantity_${detail.id}`}
                                                                    value={detail.quantity}
                                                                    onChange={(e) => handleOfferDetailChange(e, 'quantity', detail.id)}
                                                                    error={false}
                                                                    helperText=""
                                                                    sx={{ maxWidth: '150px', minWidth: '60px' }}
                                                                />
                                                                <span style={{ marginLeft: '8px', display: 'flex', alignItems: 'center', height: '100%' }}>
                                                                    {detail.unit}
                                                                </span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <DecimalTextField
                                                                    label="Precio Unitario (€)"
                                                                    name={`unit_price_${detail.id}`}
                                                                    value={detail.unit_price}
                                                                    onChange={(e) => handleOfferDetailChange(e, 'unit_price', detail.id)}
                                                                    error={false}
                                                                    helperText=""
                                                                    sx={{ maxWidth: '150px', minWidth: '60px' }}
                                                                />
                                                            </TableCell>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <TableCell>{detail.quantity} {detail.unit}</TableCell>
                                                            <TableCell>{formatPrice(detail.unit_price)}</TableCell>
                                                        </>
                                                    )}
                                                    <TableCell>{formatPrice(detail.total_price)}</TableCell>
                                                    {canUpdateOffer && (
                                                        <TableCell>
                                                            <IconButton onClick={() => handleOpenDeleteDialog(detail.id)} color="error">
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>

                                                    )}
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell rowSpan={4} colSpan={3}></TableCell>
                                                <TableCell><strong>Total</strong></TableCell>
                                                <TableCell><strong>{formatPrice(offer?.total_price)}</strong></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </CardContent>
                    </Card>
                    <Dialog open={deleteLineModal} onClose={handleCloseDialog}>
                        <DialogTitle>Confirmar eliminación</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                ¿Estás seguro de que deseas eliminar esta línea de oferta?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} color="primary">
                                Cancelar
                            </Button>
                            <Button onClick={handleConfirmDelete} color="error" autoFocus>
                                Eliminar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div >
            )
            }
        </div >
    );
}

export default OfferDetails;
