import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../components/SnackbarProvider';
import { handleApiError, formatAutocompleteOption } from '../../components/Utils';
import { useAuth } from '../login/OAuth';
import {
    Button,
    TextField,
    Grid,
    Typography,
    CircularProgress,
    Autocomplete,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    FormHelperText
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

function NewOffer() {
    const { api } = useAuth();
    const navigate = useNavigate();
    const showSnackbar = useSnackbar();

    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);

    const [productGroups, setProductGroups] = useState([]);
    const [selectedProductGroup, setSelectedProductGroup] = useState(null);

    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    const [buildings, setBuildings] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState(null);

    const [selectedQuarry, setSelectedQuarry] = useState(null);
    const [unit, setUnit] = useState("");
    const [quantities, setQuantities] = useState({});
    const [productForm, setProductForm] = useState([]);
    const [referencedBuilding, setReferencedBuilding] = useState("");

    const fetchProductGroups = useCallback(async () => {
        try {
            setLoading(true);
            const result = await api().get("/products/");

            const filteredProductGroups = result.data[0].filter(
                (productGroup) => productGroup.products.length > 0
            );

            setProductGroups(filteredProductGroups);
        } catch (error) {
            setError({ general: "Error obteniendo los grupos de productos" });
        } finally {
            setLoading(false);
        }
    }, [api]);

    const fetchClients = useCallback(async () => {
        try {
            setLoading(true);
            const result = await api().get("/clients/");
            setClients(result.data[0]);
        } catch (error) {
            setError({ general: "Error obteniendo los clientes" });
        } finally {
            setLoading(false);
        }
    }, [api]);

    
    const fetchBuildings = useCallback(async () => {
        try {
            setLoading(true);
            const result = await api().get("/buildings/");
            setBuildings(result.data[0]);
        } catch (error) {
            setError({ general: "Error obteniendo las obras" });
        } finally {
            setLoading(false);
        }
    }, [api]);
    

    useEffect(() => {
        fetchProductGroups();
        fetchClients();
        fetchBuildings();
    }, []);

    const handleProductGroupChange = (event, newValue) => {
        setSelectedProductGroup(newValue);
        setSelectedQuarry(null);
        setUnit(newValue ? newValue.unit : "");
    };

    const handleQuarryChange = (event) => {
        setSelectedQuarry(event.target.value);
    };

    const handleAddProduct = () => {
        const product = selectedProductGroup.products.find(
            p => p.quarry_id === selectedQuarry
        );

        if (product) {
            const quantity = quantities[selectedProductGroup.id];
            if (quantity > 0) {
                setProductForm(prev => [
                    ...prev,
                    {
                        productGroupName: selectedProductGroup.name,
                        quarryName: product.quarry.name,
                        product_id: product.id,
                        product_name: product.code,
                        unit_price: product.price,
                        quantity: quantity,
                        unit: unit,
                    }
                ]);
                setQuantities({ ...quantities, [selectedProductGroup.id]: 0 }); // Reset quantity
                setSelectedProductGroup(null);
                setSelectedQuarry(null);
            } else {
                setError({ quantity: "Cantidad inválida" });
            }
        }
    };

    const handleRemoveProduct = (index) => {
        const newProductForm = productForm.filter((_, i) => i !== index);
        setProductForm(newProductForm);
    };

    const createOffer = async (event) => {
        event.preventDefault();
        let error = null;
    
        try {
            // Mapeamos el productForm para quedarnos solo con product_id y quantity
            const productsToSubmit = productForm.map((product) => ({
                product_id: product.product_id,
                quantity: product.quantity,
                unit: product.unit,
            }));
            setLoading(true);

            await api().post('/offers/', {
                client_id: selectedClient.id,
                building_id: selectedBuilding?.id || null,
                referenced_building: referencedBuilding,
                offer_details: productsToSubmit // Enviamos solo product_id y quantity
            });
            showSnackbar("Oferta creado correctamente");
            navigate(`/offers/list`);
        } catch (e) {
            error = handleApiError(e, 'Error inesperado creando oferta.');
        } finally {
            if (error) setError(prevError => ({ ...prevError, general: error }));
            setLoading(false);
        }
    };
    

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Paper elevation={3} style={{ padding: 20, width: '80%' }}>
                <Typography component="h2" variant="h5">Nueva Oferta</Typography>
                <br />

                {/* Contenedor para la información general */}
                <Typography variant="h6" gutterBottom>Información General</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <Autocomplete
                            options={clients}
                            getOptionLabel={(option) => formatAutocompleteOption(option)}
                            value={selectedClient}
                            onChange={(event, newValue) => setSelectedClient(newValue)}
                            renderInput={(params) => (
                                <TextField {...params} 
                                label="Cliente" 
                                fullWidth 
                                error={!!error?.client} 
                                helperText={error?.client} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Autocomplete
                            options={buildings}
                            getOptionLabel={(option) => formatAutocompleteOption(option)}
                            value={selectedBuilding}
                            onChange={(event, newValue) => setSelectedBuilding(newValue)}
                            renderInput={(params) => (
                                <TextField {...params} 
                                label="Obra" 
                                fullWidth 
                                error={!!error?.building} 
                                helperText={error?.building} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Obra referenciada"
                            fullWidth
                            value={referencedBuilding}
                            onChange={(e) => setReferencedBuilding(e.target.value)}
                        />
                    </Grid>
                </Grid>

                <br />
                <Typography variant="h6" gutterBottom>Nueva Línea de Oferta</Typography>

                {/* Sección de agregar nueva línea de oferta */}
                <form onSubmit={createOffer}>
                    <Grid container spacing={2} alignItems="flex-start">
                        <Grid item xs={12} md={3}>
                            <Autocomplete
                                options={productGroups}
                                getOptionLabel={(option) => formatAutocompleteOption(option)}
                                value={selectedProductGroup}
                                onChange={handleProductGroupChange}
                                renderInput={(params) => (
                                    <TextField {...params} 
                                    label="Producto" 
                                    fullWidth 
                                    error={!!error?.productGroup} 
                                    helperText={error?.productGroup} />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth error={!!error?.quarry}>
                                <InputLabel id="quarry-label">Cantera</InputLabel>
                                <Select
                                    labelId="quarry-label"
                                    value={selectedQuarry || ""}
                                    onChange={handleQuarryChange}
                                    disabled={!selectedProductGroup}
                                >
                                    {selectedProductGroup && selectedProductGroup.products.map((product) => (
                                        <MenuItem key={product.quarry.id} value={product.quarry.id}>
                                            {product.quarry.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {error?.quarry && <FormHelperText>{error.quarry}</FormHelperText>}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={2}>
                            <Box display="flex" flexDirection="column" alignItems="flex-start">
                                <TextField
                                    label="Cantidad"
                                    type="number"
                                    fullWidth
                                    value={quantities[selectedProductGroup?.id] || 0}
                                    onChange={(e) => setQuantities({ ...quantities, [selectedProductGroup.id]: e.target.value })}
                                    error={!!error?.quantity}
                                    helperText={error?.quantity}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                label="Unidad"
                                value={unit}
                                disabled
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleAddProduct}
                                disabled={
                                    !selectedProductGroup || 
                                    !selectedQuarry || 
                                    !quantities[selectedProductGroup.id] || 
                                    quantities[selectedProductGroup.id] <= 0}
                            >
                                Añadir
                            </Button>
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Grupo de Producto</TableCell>
                                    <TableCell>Cantera</TableCell>
                                    <TableCell>Precio por Unidad</TableCell>
                                    <TableCell>Cantidad</TableCell>
                                    <TableCell>Unidad</TableCell>
                                    <TableCell>Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {productForm.map((product, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{product.productGroupName}</TableCell>
                                        <TableCell>{product.quarryName}</TableCell>
                                        <TableCell>{product.unit_price}</TableCell>
                                        <TableCell>{product.quantity}</TableCell>
                                        <TableCell>{product.unit}</TableCell>
                                        <TableCell>
                                            <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveProduct(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box mt={2} display="flex" justifyContent="flex-end">
                        <Button type="submit" variant="contained" color="primary" disabled={loading || productForm.length === 0 || !selectedClient}>
                            {loading ? <CircularProgress size={24} /> : 'Crear Oferta'}
                        </Button>
                    </Box>
                </form>
                {error?.general && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error.general}
                    </Typography>
                )}
            </Paper>
        </div>
    );
}

export default NewOffer;
