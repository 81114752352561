import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { handleApiError, DisableDialog, Accordion, formatDate, formatTime, formatAssignmentStatus } from '../../components/Utils';
import { useSnackbar } from '../../components/SnackbarProvider';
import {
    TextField,
    InputLabel,
    Select,
    FormControl,
    Button,
    Typography,
    Paper,
    Grid,
    Box,
    CircularProgress,
    Autocomplete,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Card
} from '@mui/material';

function TruckDriverDetails() {
    const { id } = useParams();
    const { api } = useAuth();
    const showSnackbar = useSnackbar();
    const [loading, setLoading] = useState({ general: true, update: false });
    const [error, setError] = useState({});

    const [openDisableDialog, setOpenDisableDialog] = useState(false);
    const [disableId, setDisableId] = useState(null);
    const [navigateToWhenDisabled, setNavigateToWhenDisabled] = useState(false);
    const [endpointToDisable, setEndpointToDisable] = useState(false);

    const [assignments, setAssignments] = useState([]);

    const [trucks, setTrucks] = useState([]);
    const [truckSelected, setTruckSelected] = useState(null);
    const [modalSelectTruckOpen, setModalSelectTruckOpen] = useState(false);

    const [truckDriverForm, setTruckDriverForm] = useState({
        name: null,
        email: null,
        phone: null,
        doc_type: null,
        documentation: null,
        address: null,
    });

    const fetchTruckDriver = useCallback(async () => {
        try {
            setLoading(prev => ({ ...prev, general: true }));
            const result = await api().get(`/truck_drivers/${id}`);
            const truckDriver = result.data;

            setTruckDriverForm(truckDriver);
            setAssignments(truckDriver.assignments);

        } catch (exception_error) {
            setError(prevError => ({ ...prevError, fetch: "Error obteniendo camionero" }));

        } finally {
            setLoading(prev => ({ ...prev, general: false }));
        }
    }, [api, id]);

    const fetchTrucks = useCallback(async () => {
        let fetchTrucksError = null;

        try {
            const result = await api().get('/trucks/');
            if (result.status < 300) {
                setTrucks(result.data[0]);
            } else {
                fetchTrucksError = `Error ${result.status}: ${result.data.detail}`;
            }
        } catch (error) {
            fetchTrucksError = 'Error obteniendo camiones.';
        } finally {
            setError(prevError => ({ ...prevError, fetchTrucksError: fetchTrucksError }));
        }
    }, [api]);

    useEffect(() => {
        fetchTruckDriver();
        fetchTrucks();
    }, []);

    const handleChangeForm = (e) => {
        const { name, value } = e.target;
        setTruckDriverForm(prevtruckDriverForm => ({ ...prevtruckDriverForm, [name]: value }));
    };

    const validateForm = () => {
        const errors = {};

        if (!truckDriverForm.name) {
            errors.name = "Por favor, añada un nombre.";
        }
        if (truckDriverForm.name && truckDriverForm.name.length > 36) {
            errors.name = "El nombre no debe exceder los 36 caracteres.";
        }
        if (truckDriverForm.email && truckDriverForm.email.length > 36) {
            errors.email = "El email no debe exceder los 36 caracteres.";
        }
        if (truckDriverForm.phone && truckDriverForm.phone.length > 36) {
            errors.phone = "El teléfono no debe exceder los 36 caracteres.";
        }
        if (truckDriverForm.address && truckDriverForm.address.length > 55) {
            errors.address = "La dirección no debe exceder los 55 caracteres.";
        }
        if ((truckDriverForm.doc_type && !truckDriverForm.documentation) || (!truckDriverForm.doc_type && truckDriverForm.documentation)) {
            errors.documentation = "Si quiere poner una documentación, debe rellenar ambos campos (tipo y documentación).";
        }
        if (truckDriverForm.documentation && truckDriverForm.documentation.length > 15) {
            errors.documentation = "La documentación no debe exceder los 15 caracteres.";
        }

        return errors;
    };

    const handleUpdate = async (e) => {

        let error = null;

        try {
            e.preventDefault();
            setLoading(prev => ({ ...prev, update: true }));

            const validationError = validateForm();
            setError(validationError);

            const result = await api().patch(`/truck_drivers/`, truckDriverForm);
            const truckDriver = result.data;
            setTruckDriverForm(truckDriver);
            showSnackbar('Camionero actualizado correctamente');

        } catch (e) {
            error = handleApiError(e, 'Error inesperado actualizando camión.')

        } finally {
            setError(prevError => ({ ...prevError, update: error }));
            setLoading(prev => ({ ...prev, update: false }));
        }
    };

    const handleAssociateTruck = async (e) => {

        let error = null;

        try {
            e.preventDefault();
            setLoading(prev => ({ ...prev, associate: true }));

            const result = await api().patch(`/truck_drivers/associate_truck`, { truck_driver_id: id, truck_id: truckSelected.id });

            setTruckDriverForm(result.data);
            setAssignments(result.data.assignments);
            setModalSelectTruckOpen(false);

            showSnackbar('Camión asociado correctamente');

        } catch (e) {
            error = handleApiError(e, 'Error inesperado asociando camión.')

        } finally {
            if (error) {
                showSnackbar(error, 'error');
            }
            setLoading(prev => ({ ...prev, associate: false }));
        }
    };

    const handleReleaseTruck = async (e) => {

        let error = null;

        try {
            e.preventDefault();
            setLoading(prev => ({ ...prev, release: true }));

            const result = await api().patch(`/truck_drivers/release_truck`, { id: id });

            setTruckDriverForm(result.data);
            setAssignments(result.data.assignments);

            showSnackbar('Camión liberado correctamente.');

        } catch (e) {
            error = handleApiError(e, 'Error inesperado liberando camión.')

        } finally {
            if (error) {
                showSnackbar(error, 'error');
            }
            setLoading(prev => ({ ...prev, release: false }));
        }
    };

    const handleOpenDisableDialog = (itemId, endpoint, navigateTo) => {
        setDisableId(itemId);
        setEndpointToDisable(endpoint);
        setNavigateToWhenDisabled(navigateTo);
        setOpenDisableDialog(true);
    };

    return (
        <div style={{ padding: 20 }}>
            {loading?.general && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading?.general && (
                <div>
                    <Grid container spacing={3} style={{ position: 'fixed', top: 60, right: 10, zIndex: 1100, width: 'auto' }}>
                        <Grid item>
                            <Paper elevation={0} style={{ padding: '10px', textAlign: 'center', backgroundColor: '#fff' }}>
                                <Box mb={2}>
                                    <Typography variant="h5" component="h1" gutterBottom>
                                        {truckDriverForm.code} - {truckDriverForm.name}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={10} lg={8}>
                        <Accordion title={`${truckDriverForm.code} ${truckDriverForm.name}`}>
                            <form onSubmit={handleUpdate} style={{ padding: '20px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Nombre"
                                            name="name"
                                            value={truckDriverForm.name}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 36 }}
                                            error={!!error?.name}
                                            helperText={error?.name}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            name="email"
                                            value={truckDriverForm.email}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 36 }}
                                            error={!!error?.email}
                                            helperText={error?.email}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Teléfono"
                                            name="phone"
                                            value={truckDriverForm.phone}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 36 }}
                                            error={!!error?.phone}
                                            helperText={error?.phone}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <FormControl
                                            style={{ width: "100%" }}
                                        >
                                            <InputLabel id="document-type-label">
                                                Documentación
                                            </InputLabel>
                                            <Select
                                                labelId="document-type-label"
                                                id="document-type"
                                                label='Tipo documentación'
                                                value={truckDriverForm.doc_type}
                                                onChange={handleChangeForm}
                                                name="doc_type"
                                                inputProps={{ id: 'document-type' }}
                                                error={!!error?.doc_type}
                                                helperText={error?.doc_type}
                                            >
                                                <MenuItem value="DNI">DNI</MenuItem>
                                                <MenuItem value="NIE">NIE</MenuItem>
                                                <MenuItem value="CIF">CIF</MenuItem>
                                                <MenuItem value="OTRO">OTRO</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Documentación"
                                            name="documentation"
                                            value={truckDriverForm.documentation}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 15 }}
                                            error={!!error?.documentation}
                                            helperText={error?.documentation}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={12}>
                                        <TextField
                                            fullWidth
                                            label="Dirección"
                                            name="address"
                                            value={truckDriverForm.address}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 55 }}
                                            error={!!error?.phone}
                                            helperText={error?.phone}
                                        />
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleOpenDisableDialog(id, '/truck_drivers/disable', '/truck_drivers/list')}
                                            fullWidth
                                            style={{ backgroundColor: 'red' }}>
                                            Eliminar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={loading?.update}
                                            fullWidth>
                                            {loading?.update ? (
                                                <CircularProgress size={24} color="inherit" />
                                            ) : (
                                                'Actualizar'
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                                {error.update && (
                                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                        {error.update}
                                    </Typography>
                                )}
                            </form>
                        </Accordion>
                        <Accordion title='Camiones'>
                            <Box mt={2}>
                                <Typography variant="h6">Detalles del Camión:</Typography>

                                {/* Si hay un trailer asociado */}
                                {truckDriverForm.truck ? (
                                    <>
                                        <Card variant="outlined" style={{ padding: '16px', marginTop: '16px' }}>
                                            <Link to={`/trucks/${truckDriverForm.truck.id}/details`}>
                                                {truckDriverForm.truck.license_plate}
                                            </Link>
                                            <Typography variant="subtitle1"><strong>Tara:</strong> {truckDriverForm.truck.tare} Kg</Typography>
                                        </Card>

                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            style={{ marginTop: '16px' }}
                                            onClick={handleReleaseTruck}
                                        >
                                            Liberar Camión
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        {/* Si no hay camión asociado */}
                                        <Typography variant="subtitle1" style={{ marginTop: '16px' }}>No hay camión asociado.</Typography>

                                        <Box mt={2}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{ marginRight: '10px' }}
                                                onClick={() => setModalSelectTruckOpen(true)}
                                            >
                                                Asociar Camión
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </Box>

                            {/* Tabla de Asignaciones */}
                            <Box mt={4}>
                                <Typography variant="h6">Asignaciones de camiones:</Typography>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Código</TableCell>
                                                <TableCell>Fecha</TableCell>
                                                <TableCell>Estado</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {assignments.length > 0 ? (
                                                assignments.filter(item => item.truck_id).map((item, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            <Link to={`/trucks/${item.truck.id}/details`}>
                                                                {item.truck?.license_plate || '-'}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell>{formatDate(item.date_created) + ' ' + formatTime(item.date_created) || '-'}</TableCell>
                                                        <TableCell>{formatAssignmentStatus(item.assignment_status)}</TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={2} align="center">No hay asignaciones</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Accordion>

                        {/* Modal para seleccionar Camión */}
                        <Dialog
                            open={modalSelectTruckOpen}
                            onClose={() => setModalSelectTruckOpen(false)}
                            maxWidth="sm"
                            fullWidth>
                            <DialogTitle>Seleccionar Camión</DialogTitle>
                            <br />
                            <DialogContent>
                                <Autocomplete
                                    options={trucks}
                                    getOptionLabel={(option) => option.license_plate}
                                    onChange={(event, newValue) => setTruckSelected(newValue)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Camión" variant="outlined" fullWidth />
                                    )}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setModalSelectTruckOpen(false)} color="primary">
                                    Cancelar
                                </Button>
                                <Button onClick={handleAssociateTruck} disabled={!truckSelected} color="primary">
                                    Asociar
                                </Button>
                            </DialogActions>
                        </Dialog>


                        <DisableDialog
                            open={openDisableDialog}
                            onClose={() => setOpenDisableDialog(false)}
                            endpoint={endpointToDisable}
                            disableId={disableId}
                            navigateTo={navigateToWhenDisabled}
                        />
                    </Grid>
                </div>)
            }
        </div >
    );
}

export default TruckDriverDetails;
