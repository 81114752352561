import React, { useState, useEffect, useCallback } from "react";
import Paginate from '../../components/Paginate';
import { itemsPerPage, IntegerTextField, formatAutocompleteOption } from '../../components/Utils';
import { Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    Grid,
    CircularProgress,
    Typography,
    Autocomplete
} from "@mui/material";

function ListProducts() {
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [fetchReset, setFetchReset] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(null);

    const [products, setProducts] = useState([]);
    const [name, setName] = useState(null);
    const [code, setCode] = useState(null);
    const [minStock, setMinStock] = useState(null);
    const [maxStock, setMaxStock] = useState(null);
    const [quarries, setQuarries] = useState([]);
    const [quarrySelected, setQuarrySelected] = useState(null);
    const [hasApplyed, setHasApplyed] = useState(false);
    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('ASC');

    const fetchProducts = useCallback(async () => {
        try {
            setLoading(true);
            const params = {};
            params.limit = itemsPerPage;
            params.offset = (currentPage - 1) * itemsPerPage;

            if (!fetchReset) {
                if (name) params.name = name;
                if (code) params.code = code;
                if (minStock) params.min_stock = minStock;
                if (maxStock) params.max_stock = maxStock;
                if (quarrySelected) params.quarry_id = quarrySelected.id;
                if (sortColumn) params.sort_by = sortColumn;
                if (sortDirection) params.sort_direction = sortDirection;
            } else {
                setFetchReset(false);
            }

            const result = await api().get("/products/", { params });
            setProducts(result.data[0]);
            setTotalItems(result.data[1]);
            setLoading(false);
        } catch (error) {
            setError(`Error obteniendo productos`);
        }
    }, [api, currentPage, fetchReset, code, name, minStock, maxStock, sortColumn, sortDirection]);

    const fetchQuarries = useCallback(async () => {
        try {
            const result = await api().get('/quarries/');
            setQuarries(result.data[0]);
        } catch (e) {
            setError('Error inesperado obteniendo canteras.');
        }
    }, [api]);

    useEffect(() => {
        fetchProducts();
    }, [currentPage, sortColumn, sortDirection, fetchReset]);

    useEffect(() => {
        fetchQuarries();
    }, []);

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
        } else {
            setSortColumn(column);
            setSortDirection('ASC');
        }
        setCurrentPage(1);
    };

    const handleFilters = (action) => {
        setCurrentPage(1);
        if (action === "reset") {
            setQuarrySelected(null);
            setHasApplyed(false);
            setName(null);
            setCode(null);
            setMinStock(null);
            setMaxStock(null);
            setFetchReset(true);
        } else if (action === "apply") {
            if (quarrySelected) setHasApplyed(true);
            fetchProducts();
        }
    };

    const renderProducts = () => {
        // Condiciones para mostrar la tabla según la selección de la cantera
        return products.map((productGroup) => {
            // Crear un objeto para agrupar el stock por producto
            const stockByQuarry = {};
            const unit = productGroup.unit || ''; // Obtener la unidad de medida del productGroup

            productGroup.products.forEach(item => {
                const quarryName = item.quarry?.name;
                const stock = item.stock || 0;

                // Agregar el stock al objeto según la cantera
                if (quarryName) {
                    stockByQuarry[quarryName] = (stockByQuarry[quarryName] || 0) + stock;
                }
            });

            // Crear una cadena para mostrar el stock formateado
            const stockDisplay = productGroup.has_stock
                ? Object.entries(stockByQuarry)
                    .map(([quarry, stock]) => `${quarry}: ${stock.toLocaleString('es-ES')} ${unit}`)
                    .join('\n')
                : 'No tiene stock';

            return (
                <TableRow key={productGroup.code}>
                    <TableCell component="th" scope="row">
                        <Link to={`/products/${productGroup.id}/details`}>
                            {productGroup.code || '-'}
                        </Link>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {productGroup.name || '-'}
                    </TableCell>
                    <TableCell component="th" scope="row" style={{ whiteSpace: 'pre-line' }}>
                        {stockDisplay || `0 ${unit}`} {/* Mostrar la unidad si no hay stock */}
                    </TableCell>
                </TableRow>
            );
        });
    };

    return (
        <div>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={3} sm={2}>
                                    <TextField
                                        fullWidth
                                        label="Código"
                                        name="code"
                                        value={code || ""}
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <TextField
                                        fullWidth
                                        label="Nombre"
                                        name="name"
                                        value={name || ""}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <IntegerTextField
                                        label="Mín Stock"
                                        name="min_stock"
                                        value={minStock}
                                        onChange={(e) => setMinStock(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <IntegerTextField
                                        label="Máx Stock"
                                        name="max_stock"
                                        value={maxStock}
                                        onChange={(e) => setMaxStock(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <Autocomplete
                                        options={quarries}
                                        getOptionLabel={(option) => formatAutocompleteOption(option)}
                                        value={quarrySelected}
                                        onChange={(event, newValue) => setQuarrySelected(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Canteras"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} justifyContent="flex-end">
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={() => handleFilters("apply")}>
                                        Aplicar Filtros
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="secondary" onClick={() => handleFilters("reset")}>
                                        Resetear Filtros
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <br />
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('code')} style={{ cursor: 'pointer' }}>
                                                Código {sortColumn === 'code' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('name')} style={{ cursor: 'pointer' }}>
                                                Nombre {sortColumn === 'name' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            Stock
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {renderProducts()}
                                {products.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={3} align="center">
                                            No se encontraron productos.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br/>
                    <br/>
                    <br/>
                    {totalItems && totalItems > itemsPerPage && (
                        <Paginate
                            totalItems={totalItems}
                            currentPage={currentPage}
                            onPageChange={setCurrentPage}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default ListProducts;
