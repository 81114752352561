import React, { useState, useEffect, useCallback } from "react";
import Paginate from '../../components/Paginate';
import { 
    itemsPerPage, 
    formatDate, 
    DatePickerComponent, 
    formatOfferStatus, 
    formatPrice,
    formatAutocompleteOption,    
} from '../../components/Utils';
import { Link } from 'react-router-dom';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import { useAuth } from "../login/OAuth";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Select,
    Button,
    MenuItem,
    Grid,
    FormControl,
    Autocomplete,
    InputLabel,
    CircularProgress,
    Typography
} from "@mui/material";
import dayjs from 'dayjs';
import "dayjs/locale/es";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

function ListOffers() {
    const { api } = useAuth();
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(true);
    const [fetchReset, setFetchReset] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(null);

    const [offers, setOffers] = useState([]);

    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    const [buildings, setBuildings] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState(null);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [code, setCode] = useState(null);
    const [status, setStatus] = useState('');

    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('ASC');

    const fetchOffers = useCallback(async () => {
        try {
            setLoading(true);
            const params = {};
            params.limit = itemsPerPage;
            params.offset = (currentPage - 1) * itemsPerPage
            if (!fetchReset) {
                if (selectedClient) params.client_id = selectedClient.id;
                if (selectedBuilding) params.building_id = selectedBuilding.id;
                if (code) params.code = code;
                if (status) params.offer_status = status;
                if (sortColumn) params.sort_by = sortColumn;
                if (sortDirection) params.sort_direction = sortDirection;
                if (startDate) {
                    const startDateMidday = dayjs(startDate).hour(12).minute(0).second(0);
                    params.start_date = startDateMidday.utc().format('YYYY-MM-DD');
                }
                if (endDate) {
                    const endDateMidday = dayjs(endDate).hour(12).minute(0).second(0);
                    params.end_date = endDateMidday.utc().format('YYYY-MM-DD');
                }
            } else {
                setFetchReset(false);
            }
            const result = await api().get("/offers/", { params });

            setOffers(result.data[0]);
            setTotalItems(result.data[1]);
            setLoading(false);
        } catch (error) {
            setError(prevError => ({ ...prevError, general: 'Error obteniendo ofertas.' }));
        }
    }, [api, currentPage, code, sortColumn, sortDirection, selectedClient, selectedBuilding, startDate, endDate, status, fetchReset])


    const fetchClients = useCallback(async () => {
        try {
            setLoading(true);

            const result = await api().get("/clients/");

            setClients(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError(`Error obteniendo los clientes`);
        }
    }, [api]);

    const fetchBuildings = useCallback(async () => {
        try {
            setLoading(true);
            const result = await api().get("/buildings/");
            setBuildings(result.data[0]);
        } catch (error) {
            setError({ general: "Error obteniendo las obras" });
        } finally {
            setLoading(false);
        }
    }, [api]);

    useEffect(() => {
        fetchOffers();
    }, [currentPage, sortColumn, sortDirection, fetchReset]);

    useEffect(() => {
        fetchClients();
        fetchBuildings();
    }, []);

    const handleApplyFilters = () => {
        setCurrentPage(1);
        fetchOffers();
    };
    const handleResetFilters = () => {
        setCurrentPage(1);
        setCode(null);
        setStartDate(null);
        setEndDate(null);
        setStatus(null);
        setSelectedClient(null);
        setSelectedBuilding(null);
        setFetchReset(true);
    };

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
        } else {
            setSortColumn(column);
            setSortDirection('ASC');
        }
        setCurrentPage(1);
    };

    const handleDateChange = (name, newValue) => {
        let date = dayjs(newValue).hour(12).minute(0).second(0);
        date = date.utc().format('YYYY-MM-DD');
        if (name === 'end_date') {
            setEndDate(date);
        } else if (name === 'start_date') {
            setStartDate(date);
        }
    };

    const handleStatusChange = (event) => {
        const { value } = event.target;
        setStatus(value);
    };
    const cellStyle = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '75px'
    };
    return (
        <div>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} sm={2}>
                                    <TextField
                                        fullWidth
                                        label="Código"
                                        name="code"
                                        value={code || ""}
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Autocomplete
                                        options={clients}
                                        getOptionLabel={(option) => formatAutocompleteOption(option)}
                                        value={selectedClient}
                                        onChange={(event, newValue) => setSelectedClient(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Cliente"
                                                style={{ maxWidth: '100%' }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Autocomplete
                                        options={buildings}
                                        getOptionLabel={(option) =>  formatAutocompleteOption(option)}
                                        value={selectedBuilding}
                                        onChange={(event, newValue) => setSelectedBuilding(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Obra"
                                                style={{ maxWidth: '100%' }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="status-label">Estado</InputLabel>
                                        <Select
                                            labelId="status-label"
                                            id="status"
                                            name="status"
                                            value={status}
                                            onChange={handleStatusChange}
                                            label="Tipo"
                                        >
                                            <MenuItem value="accepted">ACEPTADA</MenuItem>
                                            <MenuItem value="declined">RECHAZADA</MenuItem>
                                            <MenuItem value="pending">PENDIENTE</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            {/* Nueva fila para las fechas */}
                            <Grid container spacing={2} alignItems="center" style={{ marginTop: '16px' }}>
                                <Grid item xs={4} sm={2}>
                                    <DatePickerComponent
                                        label="Fecha Inicio"
                                        value={startDate}
                                        onChange={(newValue) => handleDateChange('start_date', newValue)}
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <DatePickerComponent
                                        label="Fecha Fin"
                                        value={endDate}
                                        onChange={(newValue) => handleDateChange('end_date', newValue)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} justifyContent="flex-end">
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={handleApplyFilters}>
                                        Aplicar Filtros
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="secondary" onClick={handleResetFilters}>
                                        Resetear Filtros
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <br />
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('code')} style={{ cursor: 'pointer' }}>
                                                Código {sortColumn === 'code' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('client_code')} style={{ cursor: 'pointer' }}>
                                                Cliente {sortColumn === 'client_code' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('building_code')} style={{ cursor: 'pointer' }}>
                                                Obra {sortColumn === 'building_code' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('offer_status')} style={{ cursor: 'pointer' }}>
                                                Estado {sortColumn === 'offer_status' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('offer_date')} style={{ cursor: 'pointer' }}>
                                                Fecha {sortColumn === 'offer_date' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('total_price')} style={{ cursor: 'pointer' }}>
                                                Precio total {sortColumn === 'total_price' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {offers.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            <Link to={`/offers/${item.id}/details`}>
                                                {item.code || '-'}
                                            </Link>
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            <Link to={`/clients/${item.client.id}/details`}>
                                                {item.client.code}
                                            </Link>
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            <Link
                                                to={item.building ? `/buildings/${item.building.id}/details` : '#'}
                                                style={{
                                                    pointerEvents: item.building ? 'auto' : 'none',
                                                    textDecoration: item.building ? 'underline' : 'none',
                                                }}>
                                                {item.building?.code || '-'}
                                            </Link>
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            {formatOfferStatus(item.offer_status)}
                                        </TableCell>
                                        <TableCell sx={cellStyle}>
                                            {formatDate(item.offer_date) || '-'}
                                        </TableCell>
                                        <TableCell sx={cellStyle}>
                                            {formatPrice(item.total_price)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
            <br />
            <br />
            <br />
            <Paginate
                totalItems={totalItems}
                onPageChange={setCurrentPage}
                currentPage={currentPage}
            />
            {error?.general && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error.general}
                </Typography>
            )}
        </div>
    )
}
export default ListOffers;