import React, { useState } from 'react';
import { useAuth } from "../pages/login/OAuth";
import { useSnackbar } from './SnackbarProvider';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    Grid,
    TextField,
    Autocomplete,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    IconButton
} from '@mui/material';
import { Delete as TrashIcon } from '@mui/icons-material';
import {
    handleApiError,formatAutocompleteOption
} from './Utils';

const AddLineModal = ({
    open,
    onClose,
    productGroups,
    id,
    isOffer,
    onSubmitSuccess
}) => {
    const { api } = useAuth();
    const showSnackbar = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [lines, setLines] = useState([{
        selectedProductGroup: null,
        selectedQuarry: "",
        quantity: "",
        unit: ""
    }]);
    const [errors, setErrors] = useState([{}]);

    const handleProductGroupChange = (index, newValue) => {
        const newLines = [...lines];
        newLines[index] = {
            ...newLines[index],
            selectedProductGroup: newValue,
            selectedQuarry: "",
            unit: newValue?.unit || ""
        };
        setLines(newLines);

        const newErrors = [...errors];
        newErrors[index] = {};
        setErrors(newErrors);
    };

    const handleQuarryChange = (index, event) => {
        const newLines = [...lines];
        newLines[index] = {
            ...newLines[index],
            selectedQuarry: event.target.value
        };
        setLines(newLines);

        const newErrors = [...errors];
        newErrors[index] = {};
        setErrors(newErrors);
    };

    const handleQuantityChange = (index, value) => {
        const newLines = [...lines];
        newLines[index] = {
            ...newLines[index],
            quantity: value
        };
        setLines(newLines);
    };

    const addNewLine = () => {
        setLines([...lines, {
            selectedProductGroup: null,
            selectedQuarry: "",
            quantity: "",
            unit: ""
        }]);
        setErrors([...errors, {}]);
    };

    const removeLine = (index) => {
        const newLines = lines.filter((_, i) => i !== index);
        const newErrors = errors.filter((_, i) => i !== index);
        setLines(newLines);
        setErrors(newErrors);
    };

    const validateLines = () => {
        const newErrors = lines.map(line => {
            const lineErrors = {};
            if (!line.selectedProductGroup) {
                lineErrors.productGroup = "Seleccione un producto";
            }
            if (!line.selectedQuarry) {
                lineErrors.quarry = "Seleccione una cantera";
            }
            if (!line.quantity || line.quantity <= 0) {
                lineErrors.quantity = "Ingrese una cantidad válida";
            }
            return lineErrors;
        });

        setErrors(newErrors);
        return newErrors.every(error => Object.keys(error).length === 0);
    };

    const handleSubmit = async () => {
        if (!validateLines()) return;

        try {
            setLoading(true);
            let payload = {};
            if (isOffer) {
                payload = {
                    offer_id: id,
                    offer_details: lines.map(line => {
                        const product = line.selectedProductGroup.products.find(
                            p => p.quarry_id === line.selectedQuarry
                        );
                        return {
                            quantity: parseFloat(line.quantity),
                            unit: line.selectedProductGroup.unit,
                            product_id: product.id
                        };
                    })
                };
            } else {
                payload = {
                    order_id: id,
                    order_details: lines.map(line => {
                        const product = line.selectedProductGroup.products.find(
                            p => p.quarry_id === line.selectedQuarry
                        );
                        return {
                            quantity: parseFloat(line.quantity),
                            unit: line.selectedProductGroup.unit,
                            product_id: product.id
                        };
                    })
                };
            }
            const endpoint = isOffer ? '/offers/add_offer_detail' : '/orders/add_order_detail';
            const response = await api().patch(`${endpoint}`, payload);

            if (onSubmitSuccess) {
                onSubmitSuccess(response.data);
            }
            setLines([{ selectedProductGroup: null, selectedQuarry: "", quantity: "", unit: "" }]);
            showSnackbar('Líneas añadidas correctamente');
            onClose();

        } catch (e) {
            const error = handleApiError(e, 'Error inesperado añadiendo líneas.');
            showSnackbar(error, 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Añadir Líneas</DialogTitle>
            <DialogContent>
                <br />
                {lines.map((line, index) => (
                    <Grid container spacing={2} alignItems="center" key={index}>
                        <Grid item xs={3}>
                            <Autocomplete
                                options={productGroups}
                                getOptionLabel={(option) => formatAutocompleteOption(option)}
                                value={line.selectedProductGroup}
                                onChange={(_, newValue) => handleProductGroupChange(index, newValue)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Producto"
                                        fullWidth
                                        error={!!errors[index]?.productGroup}
                                        helperText={errors[index]?.productGroup}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth error={!!errors[index]?.quarry}>
                                <InputLabel>Cantera</InputLabel>
                                <Select
                                    value={line.selectedQuarry}
                                    onChange={(e) => handleQuarryChange(index, e)}
                                    disabled={!line.selectedProductGroup}
                                >
                                    {line.selectedProductGroup?.products?.map((product) => (
                                        <MenuItem key={product.quarry.id} value={product.quarry.id}>
                                            {product.quarry.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{errors[index]?.quarry}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={2}>
                            <TextField
                                label="Cantidad"
                                type="number"
                                fullWidth
                                value={line.quantity}
                                onChange={(e) => handleQuantityChange(index, e.target.value)}
                                error={!!errors[index]?.quantity}
                                helperText={errors[index]?.quantity}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <TextField label="Unidad" value={line.unit} disabled fullWidth />
                        </Grid>

                        <Grid item xs={2}>
                            <IconButton onClick={() => removeLine(index)}>
                                <TrashIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
                <br />
            </DialogContent>
            <DialogActions>
                <Button onClick={addNewLine}>Añadir línea</Button>
                <Button onClick={onClose}>Cancelar</Button>
                <Button onClick={handleSubmit} color="primary" disabled={loading || lines.length === 0}>
                    {loading ? 'Guardando...' : 'Aceptar'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddLineModal;