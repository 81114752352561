import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { 
    handleApiError, 
    DisableDialog, 
    Accordion, 
    DecimalTextField, 
    DatePickerComponent, 
    formatDate, 
    formatTime, 
    formatAssignmentStatus, 
    formatTrailerType,
    formatAutocompleteOption
} from '../../components/Utils';
import { useSnackbar } from '../../components/SnackbarProvider';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import {
    TextField,
    InputLabel,
    Select,
    FormControl,
    Button,
    Typography,
    Paper,
    Grid,
    Box,
    CircularProgress,
    Autocomplete,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    MenuItem,
    FormHelperText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Card
} from '@mui/material';

dayjs.extend(utc);

function TruckDetails() {
    const { id } = useParams();
    const { api } = useAuth();
    const showSnackbar = useSnackbar();
    const [loading, setLoading] = useState({ general: true, update: false });
    const [error, setError] = useState({});

    const [openDisableDialog, setOpenDisableDialog] = useState(false);
    const [disableId, setDisableId] = useState(null);
    const [navigateToWhenDisabled, setNavigateToWhenDisabled] = useState(false);
    const [endpointToDisable, setEndpointToDisable] = useState(false);

    const [transporters, setTransporters] = useState([]);
    const [lastSavedTransporter, setLastSavedTransporter] = useState(null); // used to bring only transporter info for autocompletes
    const [transporterSelected, setTransporterSelected] = useState(null);

    const [modalSelectTrailerOpen, setModalSelectTrailerOpen] = useState(false);
    const [modalCreateTrailerOpen, setModalCreateTrailerOpen] = useState(false);

    const [modalSelectTruckDriverOpen, setModalSelectTruckDriverOpen] = useState(false);

    const [trailers, setTrailers] = useState([]);
    const [trailerSelected, setTrailerSelected] = useState(null);
    const [newTrailer, setNewTrailer] = useState({ truck_id: id, trailer_license_plate: '', trailer_type: '' });

    const [truckDrivers, setTruckDrivers] = useState([]);
    const [truckDriverSelected, setTruckDriverSelected] = useState(null);

    const [assignments, setAssignments] = useState([]);

    const [truckForm, setTruckForm] = useState({
        license_plate: null,
        tare: null,
        transporter_id: null,
        expiration_date: null,
    });

    const fetchTruck = useCallback(async () => {
        try {
            setLoading(prev => ({ ...prev, general: true }));
            const result = await api().get(`/trucks/${id}`);
            const truck = result.data

            setTruckForm(truck);
            setTransporterSelected(truck.transporter);
            setLastSavedTransporter(truck.transporter_id);
            setAssignments(truck.assignments);

        } catch (exception_error) {
            setError(prevError => ({ ...prevError, fetch: "Error obteniendo camión." }));

        } finally {
            setLoading(prev => ({ ...prev, general: false }));
        }
    }, [api, id]);

    useEffect(() => {
        fetchTruck();
        fetchTransporters();
    }, []);

    useEffect(() => {
        fetchTrailers();
        fetchTruckDrivers();
    }, [lastSavedTransporter]);

    const handleChangeTruckForm = (event) => {
        const { name, value } = event.target;
        setTruckForm(prevState => ({
            ...prevState,
            [name]: value
        }));
        setError(prevError => ({ ...prevError, [name]: null }));
    };

    const handleDateChange = (name, newValue) => {
        let date = dayjs(newValue).hour(12).minute(0).second(0);
        date = date.utc().format('YYYY-MM-DD');
        setTruckForm(prevState => ({
            ...prevState,
            [name]: date
        }));
        setError(prevError => ({
            ...prevError,
            [name]: ''
        }));
    };

    const handleTransporterChange = (event, newValue) => {
        setTransporterSelected(newValue);
        setTruckForm(prevState => ({
            ...prevState,
            transporter_id: newValue?.id
        }));
        setError(prevError => ({ ...prevError, transporter_id: null }));
    };

    const handleChangeTrailerForm = (event) => {
        const { name, value } = event.target;
        setNewTrailer(prevState => ({
            ...prevState,
            [name]: value
        }));
        setError(prevError => ({ ...prevError, [name]: null }));
    };

    const fetchTransporters = useCallback(async () => {
        let fetchTransporterError = null;

        try {
            const result = await api().get('/transporters/');
            setTransporters(result.data[0]);

        } catch (error) {
            fetchTransporterError = 'Error obteniendo transportistas.';
        } finally {
            setError(prevError => ({ ...prevError, general: fetchTransporterError }));
        }
    }, [api]);

    const fetchTrailers = useCallback(async () => {
        let fetchTrailerError = null;

        try {
            const params = { transporter_id: lastSavedTransporter }
            const result = await api().get('/trailers/', { params });
            setTrailers(result.data[0]);

        } catch (error) {
            fetchTrailerError = 'Error obteniendo remolques.';
        } finally {
            setError(prevError => ({ ...prevError, fetchTrailerError: fetchTrailerError }));
        }
    }, [api, lastSavedTransporter]);

    const fetchTruckDrivers = useCallback(async () => {
        let fetchTruckDriversError = null;

        try {
            const params = { transporter_id: lastSavedTransporter }
            const result = await api().get('/truck_drivers/', { params });
            setTruckDrivers(result.data[0]);

        } catch (error) {
            fetchTruckDriversError = 'Error obteniendo conductores.';
        } finally {
            setError(prevError => ({ ...prevError, fetchTruckDriversError: fetchTruckDriversError }));
        }
    }, [api, lastSavedTransporter]);


    const validateTruckForm = () => {
        const errors = {};

        if (!truckForm.license_plate) {
            errors.license_plate = "Por favor, añada una matrícula.";
        } else if (truckForm.license_plate.length > 10) {
            errors.license_plate = "La matrícula no debe exceder los 10 caracteres.";
        }

        if (!truckForm.tare) {
            errors.tare = "Por favor, añada una tara.";
        }

        if (!transporterSelected) {
            errors.transporter_id = "Por favor, seleccione un transportista.";
        }

        return errors;
    };

    const handleUpdate = async (e) => {

        let error = null;

        try {
            e.preventDefault();
            setLoading(prev => ({ ...prev, update: true }));

            const validationError = validateTruckForm();
            setError(validationError);

            const result = await api().patch(`/trucks/`, truckForm);
            const truck = result.data

            setTruckForm(truck);
            setTransporterSelected(truck.transporter);
            setLastSavedTransporter(truck.transporter_id);

            showSnackbar('Camión actualizado correctamente');

        } catch (e) {
            error = handleApiError(e, 'Error inesperado actualizando camión.')

        } finally {
            setError(prevError => ({ ...prevError, update: error }));
            setLoading(prev => ({ ...prev, update: false }));
        }
    };

    const validateTrailerForm = () => {
        const errors = {};

        if (!newTrailer.trailer_license_plate) {
            errors.trailer_license_plate = "Por favor, añada una matrícula.";
        } else if (newTrailer.trailer_license_plate.length > 10) {
            errors.trailer_license_plate = "La matrícula no debe exceder los 10 caracteres.";
        }

        if (!newTrailer.trailer_tare) {
            errors.trailer_tare = "Por favor, añada una tara.";
        }

        if (!newTrailer.trailer_type) {
            errors.trailer_type = "Por favor, añada un tipo.";
        }

        return errors;
    };

    const handleCreateTrailer = async (e) => {

        let error = null;

        try {
            e.preventDefault();
            setLoading(prev => ({ ...prev, createTrailer: true }));

            const validationError = validateTrailerForm();
            setError(validationError);

            const result = await api().patch(`/trucks/associate_trailer`, newTrailer);

            setTruckForm(result.data);
            setTruckForm(prevState => ({ ...prevState, truck_id: id }));
            setModalCreateTrailerOpen(false);

            showSnackbar('Remolque creado y asociado correctamente');

        } catch (e) {
            error = handleApiError(e, 'Error inesperado creando remolque.')

        } finally {
            setError(prevError => ({ ...prevError, createTrailer: error }));
            setLoading(prev => ({ ...prev, createTrailer: false }));
            fetchTrailers();
        }
    };

    const handleAssociateTrailerOrTruckDriver = async (isTrailer) => {

        let error = null;
        let unexpectedErrorMessage = isTrailer ? 'Error inesperado asociando remolque' : 'Error inesperado asociando conductor';
        let endpoint = isTrailer ? 'associate_trailer' : 'associate_driver';
        let snackbarMessage = isTrailer ? 'Remolque asociado correctamente.' : 'Conductor asociado correctamente.';

        try {
            setLoading(prev => ({ ...prev, associate: true }));

            const data = isTrailer
                ? { truck_id: id, trailer_id: trailerSelected.id }
                : { truck_id: id, truck_driver_id: truckDriverSelected.id };


            const result = await api().patch(`/trucks/${endpoint}`, data);

            setTruckForm(result.data);
            setAssignments(result.data.assignments);
            setModalSelectTrailerOpen(false);
            setModalCreateTrailerOpen(false);
            setModalSelectTruckDriverOpen(false);

            showSnackbar(snackbarMessage);

        } catch (e) {
            error = handleApiError(e, unexpectedErrorMessage)

        } finally {
            if (error) {
                showSnackbar(error, 'error');
            }
            setLoading(prev => ({ ...prev, associate: false }));
        }
    };

    const handleReleaseTrailerOrTruckDriver = async (isTrailer) => {

        let error = null;
        let unexpectedErrorMessage = isTrailer ? 'Error inesperado liberando remolque' : 'Error inesperado liberando conductor';
        let endpoint = isTrailer ? 'release_trailer' : 'release_driver';
        let snackbarMessage = isTrailer ? 'Remolque liberado correctamente.' : 'Conductor liberado correctamente.';

        try {
            setLoading(prev => ({ ...prev, release: true }));

            const result = await api().patch(`/trucks/${endpoint}`, { id: id });

            setTruckForm(result.data);
            setAssignments(result.data.assignments);

            showSnackbar(snackbarMessage);

        } catch (e) {
            error = handleApiError(e, unexpectedErrorMessage)

        } finally {
            if (error) {
                showSnackbar(error, 'error');
            }
            setLoading(prev => ({ ...prev, release: false }));
        }
    };

    const handleOpenDisableDialog = (itemId, endpoint, navigateTo) => {
        setDisableId(itemId);
        setEndpointToDisable(endpoint);
        setNavigateToWhenDisabled(navigateTo);
        setOpenDisableDialog(true);
    };

    const handleCloseCreateModal = () => {
        setModalCreateTrailerOpen(false);
        setNewTrailer({ truck_id: id, trailer_license_plate: '', trailer_type: '', trailer_tare: '' });
    };

    return (
        <div style={{ padding: 20 }}>
            {loading?.general && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading?.general && (
                <div>
                    <Grid container spacing={3} style={{ position: 'fixed', top: 60, right: 10, zIndex: 1100, width: 'auto' }}>
                        <Grid item>
                            <Paper elevation={0} style={{ padding: '10px', textAlign: 'center', backgroundColor: '#fff' }}>
                                <Box mb={2}>
                                    <Typography variant="h5" component="h1" gutterBottom>
                                        {truckForm.code} - {truckForm.license_plate}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={10} lg={8}>
                        <Accordion title={`${truckForm.code} - ${truckForm.license_plate}`}>
                            <form onSubmit={handleUpdate} style={{ padding: '20px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Matrícula"
                                            name="license_plate"
                                            value={truckForm.license_plate}
                                            onChange={handleChangeTruckForm}
                                            inputProps={{ maxLength: 10 }}
                                            error={!!error?.license_plate}
                                            helperText={error?.license_plate}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <DecimalTextField
                                            label="Tara (Tn)"
                                            name="tare"
                                            value={truckForm.tare}
                                            onChange={handleChangeTruckForm}
                                            error={!!error?.tare}
                                            helperText={error?.tare}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <Autocomplete
                                            options={transporters}
                                            getOptionLabel={(option) => formatAutocompleteOption(option)}
                                            value={transporterSelected}
                                            onChange={handleTransporterChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Transportista"
                                                    fullWidth
                                                    error={!!error?.transporter_id}
                                                    helperText={error?.transporter_id}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={6}>
                                        <DatePickerComponent
                                            label="Fecha expiración"
                                            value={truckForm.expiration_date}
                                            onChange={(newValue) => handleDateChange('expiration_date', newValue)}
                                        />
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleOpenDisableDialog(id, '/trucks/disable', '/trucks/list')}
                                            fullWidth
                                            style={{ backgroundColor: 'red' }}>
                                            Eliminar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={loading?.update}
                                            fullWidth>
                                            {loading?.update ? (
                                                <CircularProgress size={24} color="inherit" />
                                            ) : (
                                                'Actualizar'
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                                {error.update && (
                                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                        {error.update}
                                    </Typography>
                                )}
                            </form>
                        </Accordion>
                        <Accordion title='Remolque'>
                            <Box mt={2}>
                                <Typography variant="h6">Detalles del Remolque:</Typography>

                                {/* Si hay un trailer asociado */}
                                {truckForm.trailer ? (
                                    <>
                                        <Card variant="outlined" style={{ padding: '16px', marginTop: '16px' }}>
                                            <Typography variant="subtitle1"><strong>Matrícula:</strong>
                                                <Link to={`/trailers/${truckForm.trailer.id}/details`}>
                                                    {truckForm.trailer.license_plate || '-'}
                                                </Link>
                                            </Typography>
                                            <Typography variant="subtitle1"><strong>Tipo:</strong> {formatTrailerType(truckForm.trailer.trailer_type) || '-'}</Typography>
                                        </Card>

                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            style={{ marginTop: '16px' }}
                                            onClick={() => handleReleaseTrailerOrTruckDriver(true)}
                                        >
                                            Liberar Remolque
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        {/* Si no hay trailer asociado */}
                                        <Typography variant="subtitle1" style={{ marginTop: '16px' }}>No hay remolque asociado.</Typography>

                                        <Box mt={2}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{ marginRight: '10px' }}
                                                onClick={() => setModalSelectTrailerOpen(true)}
                                            >
                                                Asociar Remolque
                                            </Button>

                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => setModalCreateTrailerOpen(true)}
                                            >
                                                Crear Nuevo Remolque
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </Box>

                            {/* Tabla de Asignaciones */}
                            <Box mt={4}>
                                <Typography variant="h6">Asignaciones de remolques:</Typography>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Matrícula del Remolque</TableCell>
                                                <TableCell>Fecha</TableCell>
                                                <TableCell>Estado</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {assignments.length > 0 ? (
                                                assignments.filter(item => item.trailer_id).map((item, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            <Link to={`/trailers/${item.trailer.id}/details`}>
                                                                {item.trailer?.license_plate || '-'}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell>{formatDate(item.date_created) + ' ' + formatTime(item.date_created) || '-'}</TableCell>
                                                        <TableCell>{formatAssignmentStatus(item.assignment_status)}</TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={2} align="center">No hay asignaciones</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Accordion>
                        <Accordion title='Conductores'>
                            <Box mt={2}>
                                <Typography variant="h6">Detalles del Conductor:</Typography>

                                {/* Si hay un conductor asociado */}
                                {truckForm.truck_driver ? (
                                    <>
                                        <Card variant="outlined" style={{ padding: '16px', marginTop: '16px' }}>
                                            <Typography variant="subtitle1"><strong>Código:</strong>
                                                <Link to={`/truck_drivers/${truckForm.truck_driver.id}/details`}>
                                                    {truckForm.truck_driver.code || '-'}
                                                </Link>
                                            </Typography>
                                            <Typography variant="subtitle1"><strong>Nombre:</strong> {truckForm.truck_driver.name || '-'}</Typography>
                                            <Typography variant="subtitle1"><strong>Email:</strong> {truckForm.truck_driver.email || '-'}</Typography>
                                            <Typography variant="subtitle1"><strong>Documentación:</strong> {truckForm.truck_driver.documentation || '-'}</Typography>
                                            <Typography variant="subtitle1"><strong>Teléfono:</strong> {truckForm.truck_driver.phone || '-'}</Typography>
                                        </Card>

                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            style={{ marginTop: '16px' }}
                                            onClick={() => handleReleaseTrailerOrTruckDriver(false)}
                                        >
                                            Liberar Conductor
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        {/* Si no hay conductor asociado */}
                                        <Typography variant="subtitle1" style={{ marginTop: '16px' }}>No hay conductor asociado.</Typography>

                                        <Box mt={2}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{ marginRight: '10px' }}
                                                onClick={() => setModalSelectTruckDriverOpen(true)}
                                            >
                                                Asociar Conductor
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </Box>

                            {/* Tabla de Asignaciones */}
                            <Box mt={4}>
                                <Typography variant="h6">Asignaciones de conductores:</Typography>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Código</TableCell>
                                                <TableCell>Fecha</TableCell>
                                                <TableCell>Estado</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {assignments.length > 0 ? (
                                                assignments.filter(item => item.truck_driver_id).map((item, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            <Link to={`/truck_drivers/${item.truck_driver.id}/details`}>
                                                                {item.truck_driver?.code || '-'}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell>{formatDate(item.date_created) + ' ' + formatTime(item.date_created) || '-'}</TableCell>
                                                        <TableCell>{formatAssignmentStatus(item.assignment_status)}</TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={2} align="center">No hay asignaciones</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Accordion>

                        {/* Modal para seleccionar Trailer */}
                        <Dialog
                            open={modalSelectTrailerOpen}
                            onClose={() => setModalSelectTrailerOpen(false)}
                            maxWidth="sm"
                            fullWidth>
                            <DialogTitle>Seleccionar Remolque</DialogTitle>
                            <br />
                            <DialogContent>
                                <Autocomplete
                                    options={trailers}
                                    getOptionLabel={(option) => option.license_plate}
                                    onChange={(event, newValue) => setTrailerSelected(newValue)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Remolque" variant="outlined" fullWidth />
                                    )}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setModalSelectTrailerOpen(false)} color="primary">
                                    Cancelar
                                </Button>
                                <Button onClick={() => handleAssociateTrailerOrTruckDriver(true)} disabled={!trailerSelected} color="primary">
                                    Asociar
                                </Button>
                            </DialogActions>
                        </Dialog>

                        {/* Modal para crear nuevo Trailer */}
                        <Dialog open={modalCreateTrailerOpen} onClose={handleCloseCreateModal}>
                            <DialogTitle>Crear Nuevo Remolque</DialogTitle>
                            <DialogContent>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Matrícula del Remolque"
                                            name="trailer_license_plate"
                                            value={newTrailer.trailer_license_plate}
                                            onChange={handleChangeTrailerForm}
                                            inputProps={{ maxLength: 10 }}
                                            error={!!error?.trailer_license_plate}
                                            helperText={error?.trailer_license_plate}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl
                                            fullWidth
                                            error={!!error?.trailer_type}
                                        >
                                            <InputLabel id="trailer-type-label">
                                                Tipo de Remolque
                                            </InputLabel>
                                            <Select
                                                labelId="trailer-type-label"
                                                id="trailer-type"
                                                label='Tipo Remolque'
                                                value={newTrailer.trailer_type}
                                                onChange={handleChangeTrailerForm}
                                                name="trailer_type"
                                                inputProps={{ id: 'trailer-type' }}
                                            >
                                                <MenuItem value="Carrillero_3_ejes_rigidos">CARRILLERO 3 EJES RÍGIDOS</MenuItem>
                                                <MenuItem value="Bañera_2_ejes">BAÑERA 2 EJES</MenuItem>
                                                <MenuItem value="Bañera_3_ejes">BAÑERA 3 EJES</MenuItem>
                                                <MenuItem value="Camion_15_tn">CAMION_15_TN</MenuItem>
                                                <MenuItem value="Ninguno">NINGUNO</MenuItem>
                                            </Select>
                                            {error?.trailer_type && (
                                                <FormHelperText>{error.trailer_type}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DecimalTextField
                                            label="Tara del Remolque"
                                            name="trailer_tare"
                                            value={newTrailer.trailer_tare}
                                            onChange={handleChangeTrailerForm}
                                            error={!!error?.trailer_tare}
                                            helperText={error?.trailer_tare}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseCreateModal} color="primary">
                                    Cancelar
                                </Button>
                                <Button onClick={handleCreateTrailer} disabled={!newTrailer.trailer_license_plate || !newTrailer.trailer_tare || !newTrailer.trailer_type} color="primary">
                                    Crear
                                </Button>
                            </DialogActions>
                        </Dialog>

                        {/* Modal para seleccionar Conductor */}
                        <Dialog
                            open={modalSelectTruckDriverOpen}
                            onClose={() => setModalSelectTruckDriverOpen(false)}
                            maxWidth="sm"
                            fullWidth>
                            <DialogTitle>Seleccionar Conductor</DialogTitle>
                            <br />
                            <DialogContent>
                                <Autocomplete
                                    options={truckDrivers}
                                    getOptionLabel={(option) => formatAutocompleteOption(option)}
                                    onChange={(event, newValue) => setTruckDriverSelected(newValue)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Conductor" variant="outlined" fullWidth />
                                    )}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setModalSelectTruckDriverOpen(false)} color="primary">
                                    Cancelar
                                </Button>
                                <Button onClick={() => handleAssociateTrailerOrTruckDriver(false)} disabled={!truckDriverSelected} color="primary">
                                    Asociar
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <DisableDialog
                            open={openDisableDialog}
                            onClose={() => setOpenDisableDialog(false)}
                            endpoint={endpointToDisable}
                            disableId={disableId}
                            navigateTo={navigateToWhenDisabled}
                        />
                    </Grid>
                </div>)
            }
        </div >
    );
}

export default TruckDetails;
