import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import { useSnackbar } from '../../components/SnackbarProvider';
import { formatAutocompleteOption } from '../../components/Utils';
import {
    Autocomplete,
    Button,
    TextField,
    Grid,
    Box,
    Paper,
    Typography,
    CircularProgress
} from "@mui/material";

function NewBuilding() {

    const { api } = useAuth();
    const navigate = useNavigate();
    const showSnackbar = useSnackbar();

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const [clients, setClients] = useState([]);
    const [clientsSelected, setClientsSelected] = useState([]);

    const [buildingForm, setBuildingForm] = useState({
        name: null,
        clients_ids: []
    });

    const handleChangeForm = (event) => {
        const { name, value } = event.target;
        setBuildingForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleClientsChange = (event, newValues) => {
        setClientsSelected(newValues);
        setBuildingForm(prevState => ({
            ...prevState,
            clients_ids: newValues.map(client => client.id)
        }));
    };

    useEffect(() => {
        fetchClients();
    }, []);

    const fetchClients = useCallback(async () => {

        let fetchClientError = null;

        try {
            const result = await api().get('/clients/');
            if (result.status < 300) {
                setClients(result.data[0]);
            } else {
                fetchClientError = `Error ${result.status}: ${result.data.detail}`;
            }
        } catch (error) {
            fetchClientError = 'Error obteniendo clientes';

        } finally {
            setError(prevError => ({ ...prevError, general: fetchClientError }));
        }
    }, [api]);

    const validateForm = () => {
        const errors = {};

        if (!buildingForm.name) {
            errors.name = "Por favor, añada un nombre.";
        }
        if (buildingForm.name.length > 36) {
            errors.name = "El nombre no debe exceder los 36 caracteres.";
        }
        return Object.keys(errors).length > 0 ? errors : null;
    };

    const createBuilding = async (event) => {

        let createError = null;

        try {
            event.preventDefault();
            const validationErrors = validateForm();
            if (validationErrors) {
                setError(validationErrors);
                return;
            }

            setLoading(true);

            const result = await api().post('/buildings/', buildingForm);
            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    setError({ general: `Error: ${result.data.detail}` });
                } else {
                    showSnackbar("Obra creado correctamente");
                    navigate(`/buildings/list`);
                    setError(null);
                }
            } else {
                createError = `Error ${result.status}: ${result.data.detail}`;
            }
        } catch (error) {
            createError = `Error inesperado creando la obra.`;

        } finally {
            setError(prevError => ({ ...prevError, general: createError }));
            setLoading(false);
        }
    };


    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '120%' }}>
            <Paper elevation={3} style={{ padding: 20, width: '40%' }}>
                <Typography component="h2" variant="h5">
                    Nueva obra
                </Typography>
                <br />
                <form onSubmit={createBuilding}>
                    <Grid container spacing={2}>
                        <Grid item xs={10} md={12}>
                            <TextField
                                fullWidth
                                label="Nombre"
                                name="name"
                                value={buildingForm.name}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 36 }}
                                error={!!error?.name}
                                helperText={error?.name}
                            />
                        </Grid>
                        <Grid item xs={10} md={12}>
                            <Autocomplete
                                multiple
                                options={clients}
                                getOptionLabel={(option) => formatAutocompleteOption(option)}
                                value={clientsSelected}
                                onChange={handleClientsChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Clientes"
                                        fullWidth
                                        error={!!error?.clients}
                                        helperText={error?.clients}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <Button type="submit" variant="contained" color="primary" disabled={loading || !buildingForm.name}>
                                {loading ? <CircularProgress size={24} /> : 'Crear'}
                            </Button>
                        </Box>
                    </Grid>
                </form>
                {error?.general && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error.general}
                    </Typography>
                )}
            </Paper >
        </div >
    );
}

export default NewBuilding;
