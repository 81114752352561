import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { 
    DisableDialog, 
    Accordion, 
    DecimalTextField, 
    formatDate, 
    formatPrice, 
    handleApiError, 
    formatTime, 
    formatNumber,
    formatAutocompleteOption
} from '../../components/Utils';
import { useSnackbar } from '../../components/SnackbarProvider';
import { Delete as DeleteIcon } from "@mui/icons-material";
import ProductCosts from '../products/NewCostDetailsComponent';
import {
    TextField,
    Button,
    Typography,
    Paper,
    Grid,
    Box,
    CircularProgress,
    Autocomplete,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    IconButton,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    Table,
    TableContainer,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    DialogTitle
} from '@mui/material';

function ProductDetails() {

    const { id } = useParams();
    const { api } = useAuth();
    const showSnackbar = useSnackbar();
    const [loading, setLoading] = useState({ general: true, update: false });
    const [error, setError] = useState({});

    const [quarries, setQuarries] = useState([]);

    const [openDisableDialog, setOpenDisableDialog] = useState(false);
    const [disableId, setDisableId] = useState(null);
    const [navigateToWhenDisabled, setNavigateToWhenDisabled] = useState(false);
    const [endpointToDisable, setEndpointToDisable] = useState(false);

    const [openDeleteStockDialog, setOpenDeleteStockDialog] = useState(false);

    const [openNewCostModal, setOpenNewCostModal] = useState(false);
    const [newCost, setNewCost] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const [openAddStockModal, setOpenAddStockModal] = useState(false);
    const [selectedQuarry, setSelectedQuarry] = useState(null);
    const [newStockPrice, setNewStockPrice] = useState('');
    const [newStockValue, setNewStockValue] = useState('');
    const [productUpdates, setProductUpdates] = useState({});

    const [productForm, setProductForm] = useState({
        name: null,
        price: null,
        unit: null,
        stock: null,
        quarry_id: null,
        description: null,
        products: []
    });

    const fetchProduct = useCallback(async () => {
        try {
            setLoading(prev => ({ ...prev, general: true }));
            const result = await api().get(`/products/${id}`);
            const product = result.data
            setProductForm(product);

        } catch (error) {
            setError(prevError => ({ ...prevError, fetch: "Error obteniendo producto" }));

        } finally {
            setLoading(prev => ({ ...prev, general: false }));
        }
    }, [api, id]);

    const fetchQuarries = useCallback(async () => {

        let fetchQuarryError = null;

        try {
            const params = {}
            params.product_group_id = id;
            const result = await api().get('/quarries/', { params });
            if (result.status < 300) {
                setQuarries(result.data[0]);
            } else {
                fetchQuarryError = `Error ${result.status}: ${result.data.detail}`;
            }
        } catch (error) {
            fetchQuarryError = 'Error obteniendo canteras.';

        } finally {
            setError(prevError => ({ ...prevError, general: fetchQuarryError }));
        }
    }, [api, id]);

    useEffect(() => {
        fetchProduct();
        fetchQuarries();
    }, []);

    const handleChangeForm = (e) => {
        const { name, value } = e.target;
        setProductForm(prevProductForm => ({ ...prevProductForm, [name]: value }));
    };

    const handleAddStockToQuarry = async () => {
        let error = null;
        try {
            if (!selectedQuarry || !newStockPrice || !newStockValue) {
                showSnackbar('Por favor, complete todos los campos.', 'error');
                return;
            }

            const payload = {
                quarry_id: selectedQuarry.id,
                price: newStockPrice,
                stock: newStockValue,
                product_id: id
            };

            const result = await api().post(`/products/add_stock`, payload);

            if (result.status < 300) {
                showSnackbar('Stock añadido correctamente', 'success');
                fetchProduct();
                setOpenAddStockModal(false);
            }
        } catch (e) {
            error = handleApiError(e, 'Error inesperado añadir stock.')
        } finally {
            if (error) showSnackbar(error, 'error');
            setLoading(prev => ({ ...prev, update: false }));
        }
    };

    const validateForm = () => {
        const errors = {};

        if (!productForm.name) {
            errors.name = "Por favor, añada un nombre.";
        } else if (productForm.name.length > 36) {
            errors.name = "El nombre no debe exceder los 36 caracteres.";
        }
        if (!productForm.unit) {
            errors.unit = "Por favor, añada la unidad.";
        }

        return errors;
    };

    const handleUpdate = async (e) => {

        let error = null;

        try {
            setLoading(prev => ({ ...prev, update: true }));
            let data = productForm;
            if (openDeleteStockDialog) {
                data = { id: id, has_stock: false }
            } else {
                e.preventDefault();
                const validationError = validateForm();
                setError(validationError);
            }

            const result = await api().patch(`/products/update_product_group`, data);

            setProductForm(result.data);
            showSnackbar('Producto actualizado correctamente');

        } catch (e) {
            console.log(e)
            error = handleApiError(e, 'Error inesperado actualizando producto.')

        } finally {
            setError(prevError => ({ ...prevError, update: error }));
            setLoading(prev => ({ ...prev, update: false }));
        }
    };

    const handleAddCost = async (e) => {

        let error = null;

        try {
            e.preventDefault();
            setError(prevError => ({ ...prevError, addCostError: null }));

            const result = await api().post(`/costs/`, { product_id: selectedProduct, cost_details: newCost });
            showSnackbar('Gasto añadido correctamente');
            const product = result.data
            setProductForm(product);
            setOpenNewCostModal(false);
            setNewCost([]);

        } catch (e) {
            error = handleApiError(e, 'Error inesperado añadiendo gasto.')

        } finally {
            if (error) showSnackbar(error, 'error')
            setLoading(prev => ({ ...prev, addCostError: false }));
        }
    };

    const handleUpdateProduct = async (productId) => {
        let error = null;

        try {
            const updates = productUpdates[productId] || {};
            const productToUpdate = {
                id: productId,
                ...updates
            };
            const result = await api().patch(`/products/update_product`, productToUpdate);
            const product = result.data;

            setProductUpdates(prev => ({
                ...prev,
                [productId]: {}
            }));

            showSnackbar('Producto actualizado correctamente');
            setProductForm(product);

        } catch (e) {
            error = handleApiError(e, 'Error inesperado actualizando producto .')

        } finally {
            if (error) showSnackbar(error, 'error')
        }
    };

    const handleOpenDisableDialog = (itemId, endpoint, navigateTo) => {
        setDisableId(itemId);
        setEndpointToDisable(endpoint);
        setNavigateToWhenDisabled(navigateTo);
        setOpenDisableDialog(true);
    };

    const handleOpenNewCostModal = (productId) => {
        setOpenNewCostModal(true);
        setSelectedProduct(productId);
    };

    const handleCloseNewStockDialog = () => {
        setNewStockPrice(null);
        setNewStockValue(null);
        setSelectedQuarry(null);
        setOpenAddStockModal(false);
    };

    const handleChangeProduct = (productId, field, value) => {
        setProductUpdates(prev => ({
            ...prev,
            [productId]: {
                ...prev[productId],
                [field]: value
            }
        }));
    };

    const handleOpenDialog = () => {
        setOpenDeleteStockDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDeleteStockDialog(false);
    };

    const handleConfirm = () => {
        handleUpdate();
        setOpenDeleteStockDialog(false);
    };

    const buttonSize = productForm?.has_stock ? 3 : 4;

    return (
        <div style={{ padding: 20 }}>
            {loading?.general && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading?.general && (
                <div>
                    <Grid container spacing={3} style={{ position: 'fixed', top: 60, right: 10, zIndex: 1100, width: 'auto' }}>
                        <Grid item>
                            <Paper elevation={0} style={{ padding: '10px', textAlign: 'center', backgroundColor: '#fff' }}>
                                <Box mb={2}>
                                    <Typography variant="h5" component="h1" gutterBottom>
                                        {productForm.code} - {productForm.name}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={10} lg={8}>
                        {/* Edit product */}
                        <Accordion title={`${productForm.code} ${productForm.name}`}>
                            <form onSubmit={handleUpdate} style={{ padding: '20px' }}>
                                <Grid container spacing={2} alignItems="flex-start">
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Nombre"
                                            name="name"
                                            value={productForm.name}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 36 }}
                                            error={!!error?.name}
                                            helperText={error?.name}
                                        />
                                    </Grid>
                                    {productForm.has_stock && (
                                        <Grid item xs={10} md={2}>
                                            <DecimalTextField
                                                label="Stock"
                                                name="stock"
                                                value={productForm.products?.reduce((total, product) => total + (product.stock || 0), 0)}
                                                InputProps={{ readOnly: true }}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={10} md={2}>
                                        <FormControl
                                            style={{ width: "100%" }}
                                        >
                                            <InputLabel id="unit-label">
                                                Unidades
                                            </InputLabel>
                                            <Select
                                                labelId="unit-label"
                                                id="unit"
                                                label='Unidades'
                                                value={productForm.unit}
                                                onChange={handleChangeForm}
                                                name="unit"
                                                inputProps={{ id: 'unit' }}
                                                disabled={productForm.has_stock}
                                                error={!!error?.unit}
                                                helperText={error?.unit}
                                            >
                                                <MenuItem value="Tn">Tn</MenuItem>
                                                <MenuItem value="kg">KG</MenuItem>
                                                <MenuItem value="l">L</MenuItem>
                                                <MenuItem value="uds">UDS</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    {/* Visualización de costos y ventas en el mismo Grid */}
                                    <Grid item xs={10} md={2} sx={{ backgroundColor: '#ffebee', padding: '10px', borderRadius: '8px', maxWidth: '160px', marginLeft: '20px', marginRight: '20px', textAlign: 'center' }}>
                                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'red' }}>
                                            Costes Anuales
                                        </Typography>
                                        <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'red' }}>
                                            {formatPrice(productForm.annual_cost)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10} md={2} sx={{ backgroundColor: '#e8f5e9', padding: '10px', borderRadius: '8px', maxWidth: '160px', marginLeft: '20px', textAlign: 'center' }}>
                                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'green' }}>
                                            Ventas Anuales
                                        </Typography>
                                        <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'green' }}>
                                            {formatPrice(productForm.annual_sale)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={buttonSize}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleOpenDisableDialog(id, '/products/disable', '/products/list')}
                                            fullWidth
                                            style={{ backgroundColor: 'red' }}>
                                            Eliminar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={buttonSize}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={loading?.update}
                                            fullWidth>
                                            {loading?.update ? (
                                                <CircularProgress size={24} color="inherit" />
                                            ) : (
                                                'Actualizar'
                                            )}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={buttonSize}>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            onClick={() => setOpenAddStockModal(true)}
                                            fullWidth>
                                            Añadir stock a cantera
                                        </Button>
                                    </Grid>
                                    {productForm?.has_stock && (
                                        <Grid item xs={buttonSize}>
                                            <Button
                                                variant="contained"
                                                color="success"
                                                onClick={handleOpenDialog}
                                                fullWidth>
                                                Cambiar a sin stock
                                            </Button>
                                        </Grid>
                                    )}
                                    <Dialog
                                        open={openDeleteStockDialog}
                                        onClose={handleCloseDialog}
                                        aria-labelledby="confirm-dialog-title"
                                        aria-describedby="confirm-dialog-description"
                                    >
                                        <DialogTitle id="confirm-dialog-title">Confirmar Acción</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="confirm-dialog-description">
                                                ¿Estás seguro de que deseas cambiar este producto a "sin stock"?
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleCloseDialog} color="primary">
                                                Cancelar
                                            </Button>
                                            <Button onClick={handleConfirm} color="secondary" autoFocus>
                                                Confirmar
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Grid>
                                {error.update && (
                                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                        {error.update}
                                    </Typography>
                                )}
                            </form>
                        </Accordion>
                        {productForm.products.map((product) => (
                            <Accordion key={product.id} title={`${product.code} - ${product.quarry?.name}`}>
                                <Grid container spacing={2} style={{ padding: '20px' }} alignItems="center">
                                    {/* Título Información del producto */}
                                    <Grid item xs={12}>
                                        <Typography variant="h6" gutterBottom>
                                            Información del producto
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <strong>Stock:</strong> {product.stock || '-'} {productForm.unit || '-'}<br />
                                            <strong>Precio:</strong> {formatPrice(product.price)}<br />
                                            <strong>Costes anuales:</strong> {formatPrice(product.annual_cost)}<br />
                                            <strong>Ventas anuales:</strong> {formatPrice(product.annual_sale)}<br />
                                        </Typography>
                                    </Grid>

                                    {/* Campos del formulario */}
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            label="Añadir/Retirar Stock"
                                            name="stock"
                                            value={productUpdates[product.id]?.stock || ''}
                                            onChange={(e) => handleChangeProduct(product.id, 'stock', e.target.value)}
                                            inputProps={{ pattern: "^-?\\d*(\\.\\d{0,2})?$" }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <DecimalTextField
                                            label="Cambiar Precio"
                                            name="price"
                                            value={productUpdates[product.id]?.price || ''}
                                            onChange={(e) => handleChangeProduct(product.id, 'price', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleUpdateProduct(product.id)}
                                            fullWidth
                                            style={{ height: '100%' }}>
                                            Actualizar
                                        </Button>
                                    </Grid>

                                    <Grid item xs={12}>
                                        {/* Tabla de costes */}
                                        <Typography variant="h6" gutterBottom>
                                            Costes del producto
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleOpenNewCostModal(product.id)}
                                            style={{ marginBottom: '20px' }}
                                        >
                                            Crear nuevo coste
                                        </Button>
                                        {product.costs?.length > 0 ? (
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell><b>Nombre</b></TableCell>
                                                            <TableCell><b>Cantidad</b></TableCell>
                                                            <TableCell><b>Precio Unitario</b></TableCell>
                                                            <TableCell><b>Total</b></TableCell>
                                                            <TableCell><b>Fecha</b></TableCell>
                                                            <TableCell><b>Acciones</b></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {product.costs.flatMap((cost) => cost.cost_details).map((detail) => (
                                                            <TableRow key={detail.id}>
                                                                <TableCell>{detail.name}</TableCell>
                                                                <TableCell>{detail.quantity} {detail.unit}</TableCell>
                                                                <TableCell>{formatPrice(detail.unit_price)}</TableCell>
                                                                <TableCell>{formatPrice(detail.total_price)}</TableCell>
                                                                <TableCell>{formatDate(detail.date_created)}</TableCell>
                                                                <TableCell>
                                                                    <IconButton
                                                                        edge="end"
                                                                        color="secondary"
                                                                        onClick={() => handleOpenDisableDialog(detail.id, '/costs/disable_cost_detail')}
                                                                    >
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        ) : (
                                            <Typography variant="body2" color="textSecondary">
                                                No hay detalles de costos para este producto.
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" gutterBottom>
                                            Movimientos de Stock
                                        </Typography>
                                        {product?.movements && product.movements.length > 0 ? (
                                            <TableContainer component={Paper} sx={{ marginTop: '10px', maxWidth: '600px' }}>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="left"><strong>Fecha</strong></TableCell>
                                                            <TableCell align="left"><strong>Cantidad</strong></TableCell>
                                                            <TableCell align="left"><strong>Stock</strong></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {[...product.movements]
                                                            .sort((a, b) => new Date(a.movement_date) - new Date(b.movement_date))
                                                            .reduce((acc, movement) => {
                                                                const prevStock = acc.length > 0 ? acc[acc.length - 1].stock : 0;
                                                                const quantity = movement.movement_type === 'purchase' ? `+${movement.quantity}` : `-${movement.quantity}`;
                                                                const stock = movement.movement_type === 'purchase' ? prevStock + movement.quantity : prevStock - movement.quantity;

                                                                acc.push({
                                                                    id: movement.id,
                                                                    date: formatDate(movement.movement_date) + ' ' + formatTime(movement.movement_date),
                                                                    quantity,
                                                                    stock,
                                                                    type: movement.movement_type
                                                                });

                                                                return acc;
                                                            }, [])
                                                            .reverse()
                                                            .map((movement) => (
                                                                <TableRow key={movement.id} sx={{ backgroundColor: movement.type === 'purchase' ? '#e8f5e9' : '#ffebee' }}>
                                                                    <TableCell align="left">{movement.date}</TableCell>
                                                                    <TableCell align="left">{movement.quantity}</TableCell>
                                                                    <TableCell align="left">{formatNumber(movement.stock)}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        ) : (
                                            <Typography variant="body2" color="textSecondary">
                                                No hay movimientos de stock para este producto.
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Accordion>
                        ))}
                    </Grid>

                    {/* Modal para crear nuevo coste */}
                    <Dialog
                        open={openNewCostModal}
                        onClose={(e) => setOpenNewCostModal(false)}
                        maxWidth="xl"
                        fullWidth
                        PaperProps={{
                            style: { width: '100%', maxHeight: '100%' } // Ajusta el tamaño según sea necesario
                        }}
                    >
                        <DialogContent>
                            <ProductCosts title={'Crear nuevo coste'} costs={newCost} setCosts={setNewCost} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={(e) => setOpenNewCostModal(false)} color="primary">
                                Cerrar
                            </Button>
                            <Button onClick={handleAddCost} variant='contained' color="primary">
                                Enviar Coste
                            </Button>
                        </DialogActions>
                        {error.addCostError && (
                            <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                {error.addCostError}
                            </Typography>
                        )}
                    </Dialog>
                    {/* Modal para añadir stock a cantera */}
                    <Dialog
                        open={openAddStockModal}
                        onClose={handleCloseNewStockDialog}
                        maxWidth="sm"
                        fullWidth
                    >
                        <DialogTitle>Añadir nuevo stock a cantera</DialogTitle>
                        <DialogContent>
                            <Autocomplete
                                options={quarries}
                                getOptionLabel={(option) => formatAutocompleteOption(option)}
                                value={selectedQuarry}
                                onChange={(event, newValue) => setSelectedQuarry(newValue)} 
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Seleccionar cantera"
                                        fullWidth
                                        margin='normal'
                                    />
                                )}
                            />
                            <DecimalTextField
                                label="Precio"
                                margin='normal'
                                name="price"
                                value={newStockPrice}
                                onChange={(e) => setNewStockPrice(e.target.value)} // Guardamos el precio
                            />
                            <DecimalTextField
                                label="Stock"
                                margin='normal'
                                name="stock"
                                value={newStockValue}
                                onChange={(e) => setNewStockValue(e.target.value)} // Guardamos el stock
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseNewStockDialog} color="primary">
                                Cancelar
                            </Button>
                            <Button onClick={handleAddStockToQuarry} disabled={!selectedQuarry || !newStockPrice || !newStockValue} variant='contained' color="primary">
                                Aceptar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <DisableDialog
                        open={openDisableDialog}
                        onClose={() => setOpenDisableDialog(false)}
                        endpoint={endpointToDisable}
                        disableId={disableId}
                        navigateTo={navigateToWhenDisabled}
                        fetch={fetchProduct}
                    />
                </div>)
            }
        </div >
    );
}

export default ProductDetails;
