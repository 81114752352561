import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import { useSnackbar } from '../../components/SnackbarProvider';
import { handleApiError } from '../../components/Utils';
import {
    Button,
    TextField,
    Grid,
    Box,
    Paper,
    Typography,
    CircularProgress
} from "@mui/material";

function NewProvider() {
    const { api } = useAuth();
    const showSnackbar = useSnackbar();
    const navigate = useNavigate();

    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);

    const [providerForm, setProviderForm] = useState({
        name: null,
        phone: null,
        cif: null,
        email: null,
        address: null,
        country: null,
        zip_code: null,
        commercial_name: null,
        region: null,
        province: null,
        commercial_name: null,
        administrative_name: null,
        administrative_phone: null,
        administrative_email: null,
        administrative_position: null,
        sales_representative_name: null,
        sales_representative_phone: null,
        sales_representative_email: null,
        sales_representative_position: null,
    });

    const handleChangeForm = (event) => {
        const { name, value } = event.target;
        setProviderForm(prevState => ({
            ...prevState,
            [name]: value
        }));
        setError(prevError => ({ ...prevError, [name]: null }));
    };

    const validateForm = () => {
        const errors = {};

        if (!providerForm.name) {
            errors.name = "Por favor, añada un nombre";
        } else if (providerForm.name.length > 36) {
            errors.name = "El nombre no debe exceder los 36 caracteres";
        }
        if (providerForm.email && providerForm.email.length > 36) {
            errors.email = "El email no debe exceder los 36 caracteres";
        }
        if (providerForm.country && providerForm.country.length > 36) {
            errors.country = "El pais no debe exceder los 36 caracteres";
        }
        if (providerForm.phone && providerForm.phone.length > 15) {
            errors.phone = "El teléfono no debe exceder los 15 caracteres.";
        }
        if (providerForm.zip_code && providerForm.zip_code.length > 40) {
            errors.zip_code = "El código postal no debe exceder los 40 caracteres.";
        }
        if (providerForm.province && providerForm.province.length > 150) {
            errors.province = "La provincia no debe exceder los 150 caracteres.";
        }
        if (providerForm.address && providerForm.address.length > 150) {
            errors.address = "La dirección no debe exceder los 150 caracteres.";
        }
        if (providerForm.region && providerForm.region.length > 150) {
            errors.region = "La localidad no debe exceder los 150 caracteres.";
        }
        if (providerForm.commercial_name && providerForm.commercial_name.length > 50) {
            errors.commercial_name = "El alias no debe exceder los 50 caracteres.";
        }
        if (providerForm.administrative_name && providerForm.administrative_name.length > 50) {
            errors.administrative_name = "El nombre administrativo no debe exceder los 50 caracteres.";
        }
        if (providerForm.administrative_phone && providerForm.administrative_phone.length > 36) {
            errors.administrative_phone = "El teléfono administrativo no debe exceder los 36 caracteres.";
        }
        if (providerForm.administrative_email && providerForm.administrative_email.length > 36) {
            errors.administrative_email = "El email administrativo no debe exceder los 36 caracteres.";
        }
        if (providerForm.administrative_position && providerForm.administrative_position.length > 36) {
            errors.administrative_position = "La posición administrativa no debe exceder los 36 caracteres.";
        }
        if (providerForm.sales_representative_name && providerForm.sales_representative_name.length > 50) {
            errors.sales_representative_name = "El nombre del representante de ventas no debe exceder los 50 caracteres.";
        }
        if (providerForm.sales_representative_phone && providerForm.sales_representative_phone.length > 36) {
            errors.sales_representative_phone = "El teléfono del representante de ventas no debe exceder los 36 caracteres.";
        }
        if (providerForm.sales_representative_email && providerForm.sales_representative_email.length > 36) {
            errors.sales_representative_email = "El email del representante de ventas no debe exceder los 36 caracteres.";
        }
        if (providerForm.sales_representative_position && providerForm.sales_representative_position.length > 36) {
            errors.sales_representative_position = "La posición del representante de ventas no debe exceder los 36 caracteres.";
        }

        return Object.keys(errors).length > 0 ? errors : null;
    };

    const createProvider = async (event) => {
        let error = null;

        try {
            event.preventDefault();
            const validationErrors = validateForm();
            if (validationErrors) {
                setError(validationErrors);
                return;
            }
            setLoading(true);

            await api().post('/providers/', providerForm);

            showSnackbar("Proveedor creado correctamente");
            navigate(`/providers/list`);
            setError(null);

        } catch (e) {
            error = handleApiError(e, 'Error inesperado creando proveedor.')
        } finally {
            setError(prevError => ({ ...prevError, general: error }));
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '120%' }}>
            <Paper elevation={3} style={{ padding: 20, width: '80%' }}>
                <Typography component="h2" variant="h5">
                    Nuevo Proveedor
                </Typography>
                <br />
                <form onSubmit={createProvider}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ marginTop: "20px" }}>
                            <Typography variant="h6">Información Principal</Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Nombre"
                                name="name"
                                value={providerForm.name}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 36 }}
                                error={!!error?.name}
                                helperText={error?.name}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Email"
                                name="email"
                                value={providerForm.email}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 36 }}
                                error={!!error?.email}
                                helperText={error?.email}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Teléfono"
                                name="phone"
                                value={providerForm.phone}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 15 }}
                                error={!!error?.phone}
                                helperText={error?.phone}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Nombre comercial (alias)"
                                name="commercial_name"
                                value={providerForm.commercial_name}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 36 }}
                                error={!!error?.commercial_name}
                                helperText={error?.commercial_name}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "20px" }}>
                            <Typography variant="h6">Documentación</Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="CIF"
                                name="cif"
                                value={providerForm.cif}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 9 }}
                                error={!!error?.cif}
                                helperText={error?.cif}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "20px" }}>
                            <Typography variant="h6">Dirección</Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="País"
                                name="country"
                                value={providerForm.country}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 36 }}
                                error={!!error?.country}
                                helperText={error?.country}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Provincia"
                                name="province"
                                value={providerForm.province}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 150 }}
                                error={!!error?.province}
                                helperText={error?.province}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Localidad"
                                name="region"
                                value={providerForm.region}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 150 }}
                                error={!!error?.region}
                                helperText={error?.region}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Código postal"
                                name="zip_code"
                                value={providerForm.zip_code}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 9 }}
                                error={!!error?.zip_code}
                                helperText={error?.zip_code}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                label="Dirección"
                                name="address"
                                value={providerForm.address}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 150 }}
                                error={!!error?.address}
                                helperText={error?.address}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "20px" }}>
                            <Typography variant="h6">Contactos</Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Nombre Administrativo"
                                name="administrative_name"
                                value={providerForm.administrative_name}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 50 }}
                                error={!!error?.administrative_name}
                                helperText={error?.administrative_name}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Teléfono Administrativo"
                                name="administrative_phone"
                                value={providerForm.administrative_phone}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 36 }}
                                error={!!error?.administrative_phone}
                                helperText={error?.administrative_phone}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Email Administrativo"
                                name="administrative_email"
                                value={providerForm.administrative_email}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 36 }}
                                error={!!error?.administrative_email}
                                helperText={error?.administrative_email}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Posición Administrativo"
                                name="administrative_position"
                                value={providerForm.administrative_position}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 36 }}
                                error={!!error?.administrative_position}
                                helperText={error?.administrative_position}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Nombre Representante de Ventas"
                                name="sales_representative_name"
                                value={providerForm.sales_representative_name}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 50 }}
                                error={!!error?.sales_representative_name}
                                helperText={error?.sales_representative_name}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Teléfono Representante de Ventas"
                                name="sales_representative_phone"
                                value={providerForm.sales_representative_phone}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 36 }}
                                error={!!error?.sales_representative_phone}
                                helperText={error?.sales_representative_phone}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Email Representante de Ventas"
                                name="sales_representative_email"
                                value={providerForm.sales_representative_email}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 36 }}
                                error={!!error?.sales_representative_email}
                                helperText={error?.sales_representative_email}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Posición Representante de Ventas"
                                name="sales_representative_position"
                                value={providerForm.sales_representative_position}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 36 }}
                                error={!!error?.sales_representative_position}
                                helperText={error?.sales_representative_position}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <Button type="submit" variant="contained" color="primary" disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : 'Crear'}
                            </Button>
                        </Box>
                    </Grid>
                </form>
                {error?.general && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error.general}
                    </Typography>
                )}
            </Paper >
        </div>
    );
}

export default NewProvider;
