import React, { useState, useEffect, useRef } from "react";
import { styled, useTheme } from '@mui/material/styles';
import {
    Box,
    Drawer as MuiDrawer,
    AppBar as MuiAppBar,
    Toolbar,
    List,
    CssBaseline,
    Collapse,
    Typography,
    Divider,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import {
    Menu as MenuIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from "../pages/login/OAuth";
import { FaTruck, FaVest, FaFileAlt, FaBuilding, FaBox, FaCoins, FaFileInvoice, FaTag } from 'react-icons/fa';
import { MdBusiness, MdBuild, MdLocalShipping, MdPeople, MdOutlineInventory2 } from 'react-icons/md';


const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: open ? drawerWidth : 0, // Desplazar el AppBar también
    width: open ? `calc(100% - ${drawerWidth}px)` : '100%', // Reducir ancho cuando el Drawer esté abierto
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


const menuItems = [
    {
        name: 'Albaranes', icon: < FaFileAlt />, path: '/delivery_notes', subMenu: [
            { name: 'Nuevo albarán', path: '/delivery_notes/new', allowedRol: ["User", "admin"] },
            { name: 'Ver albaranes', path: '/delivery_notes/list', allowedRol: ["User", "admin"] },
        ]
    },
    {
        name: 'Pedidos venta', icon: < FaFileInvoice />, path: '/orders', subMenu: [
            { name: 'Nuevo pedido', path: '/orders/new', allowedRol: ["User", "admin"] },
            { name: 'Ver pedidos', path: '/orders/list', allowedRol: ["User", "admin"] },
        ]
    },
    {
        name: 'Ofertas', icon: < FaTag />, path: '/offers', subMenu: [
            { name: 'Nueva oferta', path: '/offers/new', allowedRol: ["User", "admin"] },
            { name: 'Ver ofertas', path: '/offers/list', allowedRol: ["User", "admin"] },
        ]
    },
    {
        name: 'Clientes', icon: <MdPeople />, path: '/clients', subMenu: [
            { name: 'Nuevo cliente', path: '/clients/new', allowedRol: ["User", "admin"] },
            { name: 'Ver clientes', path: '/clients/list', allowedRol: ["User", "admin"] },
        ]
    },
    {
        name: 'Transportistas', icon: <MdLocalShipping />, path: '/transporters', subMenu: [
            { name: 'Nuevo transportista', path: '/transporters/new', allowedRol: ["User", "admin"] },
            { name: 'Ver transportistas', path: '/transporters/list', allowedRol: ["User", "admin"] },
        ]
    },
    {
        name: 'Op. transporte', icon: <MdBuild />, path: '/operators', subMenu: [
            { name: 'Nuevo op. transporte', path: '/operators/new', allowedRol: ["User", "admin"] },
            { name: 'Ver ops. transporte', path: '/operators/list', allowedRol: ["User", "admin"] },
        ]
    },
    {
        name: 'Proveedores', icon: <MdBusiness />, path: '/providers', subMenu: [
            { name: 'Nuevo proveedor', path: '/providers/new', allowedRol: ["User", "admin"] },
            { name: 'Ver proveedores', path: '/providers/list', allowedRol: ["User", "admin"] },

        ]
    },
    {
        name: 'Pedidos proveedor', icon: <MdOutlineInventory2 />, path: '/provider_orders', subMenu: [
            { name: 'Nuevo pedido proveedor', path: '/provider_orders/new', allowedRol: ["User", "admin"] },
            { name: 'Ver pedidos ', path: '/provider_orders/list', allowedRol: ["User", "admin"] },

        ]
    },
    {
        name: 'Obras', icon: <FaBuilding />, path: '/buildings', subMenu: [
            { name: 'Nueva obra', path: '/buildings/new', allowedRol: ["User", "admin"] },
            { name: 'Ver obras', path: '/buildings/list', allowedRol: ["User", "admin"] }
        ]
    },
    {
        name: 'Productos', icon: <FaBox />, path: '/products', subMenu: [
            { name: 'Nuevo producto', path: '/products/new', allowedRol: ["User", "admin"] },
            { name: 'Ver productos', path: '/products/list', allowedRol: ["User", "admin"] },
            { name: 'Ver/crear canteras', path: '/quarries/list', allowedRol: ["User", "admin"] },
        ]
    },
    {
        name: 'Vehículos', icon: <FaTruck />, path: '/trucks', subMenu: [
            { name: 'Nuevo camión', path: '/trucks/new', allowedRol: ["User", "admin"] },
            { name: 'Nuevo remolque', path: '/trailers/new', allowedRol: ["User", "admin"] },
            { name: 'Ver camiones', path: '/trucks/list', allowedRol: ["User", "admin"] },
            { name: 'Ver remolques', path: '/trailers/list', allowedRol: ["User", "admin"] },
        ]
    },
    {
        name: 'Camioneros', icon: <FaVest />, path: '/truck_drivers', subMenu: [
            { name: 'Nuevo camionero', path: '/truck_drivers/new', allowedRol: ["User", "admin"] },
            { name: 'Ver camioneros', path: '/truck_drivers/list', allowedRol: ["User", "admin"] }
        ]
    },
    {
        name: 'Facturas', icon: <FaCoins />, path: '/invoices', allowedRol: ["admin", 'superadmin'],
        subMenu: [
            { name: 'Ver facturas', path: '/invoices/list', allowedRol: ["admin", 'superadmin'] },
        ]
    }
];

const routesMapping = [
    // Autenticación
    { path: '/sign-in', title: 'Iniciar Sesión' },
    { path: '/sign-up', title: 'Registro' },
    { path: '/reset-pass', title: 'Restablecer Contraseña' },
    { path: '/reset-pass/:token', title: 'Nueva Contraseña' },

    // Clientes
    { path: '/clients/new', title: 'Nuevo Cliente' },
    { path: '/clients/list', title: 'Listado de Clientes' },
    { path: '/clients/:id/details', title: 'Detalles Cliente' },

    // Productos
    { path: '/products/new', title: 'Nuevo Producto' },
    { path: '/products/list', title: 'Listado de Productos' },
    { path: '/products/:id/details', title: 'Detalles Producto' },

    // Pedidos
    { path: '/orders/new', title: 'Nuevo Pedido' },
    { path: '/orders/list', title: 'Listado de Pedidos' },
    { path: '/orders/:id/details', title: 'Detalles Pedido' },

    // Albaranes
    { path: '/delivery_notes/new', title: 'Nuevo Albarán' },
    { path: '/delivery_notes/list', title: 'Listado de Albaranes' },
    { path: '/delivery_notes/:id/details', title: 'Detalles Albarán' },

    // Facturas
    { path: '/invoices/list', title: 'Listado de Facturas' },
    { path: '/invoices/:id/details', title: 'Detalles Factura' },
    { path: '/invoices_provider/:id/details', title: 'Detalles Factura Proveedor' },

    // Ofertas
    { path: '/offers/new', title: 'Nueva Oferta' },
    { path: '/offers/list', title: 'Listado de Ofertas' },
    { path: '/offers/:id/details', title: 'Detalles Oferta' },

    // Proveedores
    { path: '/providers/new', title: 'Nuevo Proveedor' },
    { path: '/providers/list', title: 'Listado de Proveedores' },
    { path: '/providers/:id/details', title: 'Detalles Proveedor' },

    // Transportistas
    { path: '/transporters/new', title: 'Nuevo Transportista' },
    { path: '/transporters/list', title: 'Listado de Transportistas' },
    { path: '/transporters/:id/details', title: 'Detalles Transportista' },

    // Operadores de transporte
    { path: '/operators/new', title: 'Nuevo Operador Transporte' },
    { path: '/operators/list', title: 'Listado de Operadores Transporte' },
    { path: '/operators/:id/details', title: 'Detalles Operador Transporte' },

    // Obras
    { path: '/buildings/new', title: 'Nueva Obra' },
    { path: '/buildings/list', title: 'Listado de Obras' },
    { path: '/buildings/:id/details', title: 'Detalles Obra' },

    // Camiones
    { path: '/trucks/new', title: 'Nuevo Camión' },
    { path: '/trucks/list', title: 'Listado de Camiones' },
    { path: '/trucks/:id/details', title: 'Detalles Camión' },

    // Remolques
    { path: '/trailers/new', title: 'Nuevo Remolque' },
    { path: '/trailers/list', title: 'Listado de Remolques' },
    { path: '/trailers/:id/details', title: 'Detalles Remolque' },

    // Camioneros
    { path: '/truck_drivers/new', title: 'Nuevo Camionero' },
    { path: '/truck_drivers/list', title: 'Listado de Camioneros' },
    { path: '/truck_drivers/:id/details', title: 'Detalles Camionero' },

    // Canteras
    { path: '/quarries/list', title: 'Listado de Canteras' },
    { path: '/quarries/:id/details', title: 'Detalles Cantera' },

    // Pedidos Proveedor
    { path: '/provider_orders/new', title: 'Nuevo Pedido Proveedor' },
    { path: '/provider_orders/list', title: 'Listado de Pedidos Proveedor' },
    { path: '/provider_orders/:id/details', title: 'Detalles Pedido Proveedor' },

    // Albaranes Proveedor
    { path: '/provider_delivery_notes/:id/details', title: 'Detalles Albarán Proveedor' }
];


export default function MiniDrawer() {
    const theme = useTheme();
    const [isOpen, setIsOpen] = React.useState(false);
    const { authenticated, logout, userRole } = useAuth()
    const drawerRef = useRef(null);
    const location = useLocation();

    const [openSubMenu, setOpenSubMenu] = useState(null);

    const getPageTitle = () => {
        let title = "Inicio";

        const matchPath = (pattern, path) => {
            const regex = new RegExp(`^${pattern.replace(/:\w+/g, '[^/]+')}$`);
            return regex.test(path);
        };

        routesMapping.forEach(route => {
            if (matchPath(route.path, location.pathname)) {
                title = route.title;
            }
        });

        return title;
    };


    const handleSubMenuClick = (path) => {
        setOpenSubMenu(openSubMenu === path ? null : path);
        handleDrawerOpen();
    };

    const handleDrawerOpen = () => {
        setIsOpen(true);
    };

    useEffect(() => {
        if (!authenticated) {
            handleDrawerClose();
        }
    }, [authenticated]);

    const handleDrawerClose = () => {
        setIsOpen(false);
        setOpenSubMenu(false);
    };

    const handleClick = () => {
        setOpenSubMenu(false)
        setIsOpen(false);
    };

    const handleClickOutside = (event) => {
        if (drawerRef.current && !drawerRef.current.contains(event.target)) {
            handleDrawerClose();
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={isOpen} sx={{ backgroundColor: '#E66136' }}>
                <Toolbar>
                    {authenticated && (

                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(isOpen && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Typography variant="h6" noWrap component={Link} to="/" color="inherit" sx={{ flexGrow: 1, textDecoration: 'none' }}>
                        GRAHOR - {getPageTitle()}
                    </Typography>

                    {authenticated ? (
                        <Typography variant="body1" noWrap color="inherit" component={Link} to="/" onClick={logout} sx={{ textDecoration: 'none' }}>
                            Cerrar sesión
                        </Typography>

                    ) : (
                        <></>
                    )}
                </Toolbar>
            </AppBar>
            {authenticated && (

                <Drawer variant="permanent" open={isOpen} ref={drawerRef}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />

                    <List>
                        {menuItems.map((item) => (
                            <div key={item.name}>
                                <ListItem disablePadding sx={{ display: 'block' }}>
                                    {item.subMenu ? (
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: isOpen ? 'initial' : 'center',
                                                px: 3.5,
                                            }}
                                            onClick={() => handleSubMenuClick(item.path)}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: isOpen ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} sx={{ opacity: isOpen ? 1 : 0 }} />
                                            {isOpen ? <ExpandMoreIcon /> : <></>}
                                        </ListItemButton>
                                    ) : (
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: isOpen ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                            component={Link}
                                            to={item.path}
                                            onClick={() => handleClick(item.name)}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: isOpen ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} sx={{ opacity: isOpen ? 1 : 0 }} />
                                        </ListItemButton>
                                    )}
                                </ListItem>
                                {item.subMenu && (
                                    <Collapse in={openSubMenu === item.path} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {item.subMenu.map((subItem) => {
                                                const showItem = subItem.allowedRol.includes(userRole);
                                                return showItem && (
                                                    <ListItem key={subItem.name} disablePadding>
                                                        <ListItemButton
                                                            sx={{
                                                                minHeight: 48,
                                                                justifyContent: 'center',
                                                                px: 4,
                                                            }}
                                                            component={Link}
                                                            to={subItem.path}
                                                            onClick={() => handleClick(item.name)}
                                                        >
                                                            <ListItemText primary={subItem.name} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </Collapse>
                                )}
                            </div>
                        ))}
                    </List>
                </Drawer>
            )}
        </Box>
    );
}