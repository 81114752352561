import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { useSnackbar } from '../../components/SnackbarProvider';
import InvoiceTable from '../../components/InvoiceTable';
import {
    handleApiError,
    DisableDialog,
    Accordion,
    UploadDocumentButton,
    DownloadDocumentButton,
    formatDate,
    formatPrice,
    formatOrderStatus
} from '../../components/Utils';
import {
    TextField,
    Button,
    Typography,
    Grid,
    CircularProgress,
    Paper,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';


function ProviderDetails() {
    const { id } = useParams();
    const { api } = useAuth();
    const showSnackbar = useSnackbar();

    const [loading, setLoading] = useState({ general: true, update: false });
    const [error, setError] = useState({});

    const [invoices, setInvoices] = useState([]);
    const [totalPriceSum, setTotalPriceSum] = useState(null);
    const [paidAmountSum, setPaidAmountSum] = useState(null);
    const [remainingAmountSum, setRemainingAmountSum] = useState(null);

    const [openDisableDialog, setOpenDisableDialog] = useState(false);
    const [disableId, setDisableId] = useState(null);
    const [navigateToWhenDisabled, setNavigateToWhenDisabled] = useState(false);
    const [endpointToDisable, setEndpointToDisable] = useState(false);

    const [providerForm, setProviderForm] = useState({});

    const [deliveryNotes, setDeliveryNotes] = useState({});
    const [orders, setOrders] = useState({});

    const fetchProvider = useCallback(async () => {
        try {
            setLoading(prev => ({ ...prev, general: true }));
            const result = await api().get(`/providers/${id}`);
            const data = result.data;

            setProviderForm(data.provider);
            setDeliveryNotes(data.delivery_notes);
            setOrders(data.provider_orders);
            setInvoices(data.invoices);
            setTotalPriceSum(data.total_price_sum);
            setPaidAmountSum(data.paid_amount_sum);
            setRemainingAmountSum(data.remaining_amount_sum);

        } catch (exception_error) {
            setError(prevError => ({ ...prevError, fetch: "Error obteniendo proveedor" }));

        } finally {
            setLoading(prev => ({ ...prev, general: false }));
        }
    }, [api, id]);

    useEffect(() => {
        fetchProvider();
    }, []);

    const handleChangeForm = (e) => {
        const { name, value } = e.target;
        setProviderForm(prevTrailerForm => ({ ...prevTrailerForm, [name]: value }));
    };

    const validateForm = () => {
        const errors = {};

        if (!providerForm.name) {
            errors.name = "Por favor, añada un nombre";
        } else if (providerForm.name.length > 36) {
            errors.name = "El nombre no debe exceder los 36 caracteres";
        }
        if (providerForm.email && providerForm.email.length > 36) {
            errors.email = "El email no debe exceder los 36 caracteres";
        }
        if (providerForm.country && providerForm.country.length > 36) {
            errors.country = "El pais no debe exceder los 36 caracteres";
        }
        if (providerForm.phone && providerForm.phone.length > 15) {
            errors.phone = "El teléfono no debe exceder los 15 caracteres.";
        }
        if (providerForm.zip_code && providerForm.zip_code.length > 40) {
            errors.zip_code = "El código postal no debe exceder los 40 caracteres.";
        }
        if (providerForm.province && providerForm.province.length > 150) {
            errors.province = "La provincia no debe exceder los 150 caracteres.";
        }
        if (providerForm.address && providerForm.address.length > 150) {
            errors.address = "La dirección no debe exceder los 150 caracteres.";
        }
        if (providerForm.region && providerForm.region.length > 150) {
            errors.region = "La localidad no debe exceder los 150 caracteres.";
        }
        if (providerForm.commercial_name && providerForm.commercial_name.length > 50) {
            errors.commercial_name = "El alias no debe exceder los 50 caracteres.";
        }
        if (providerForm.administrative_name && providerForm.administrative_name.length > 50) {
            errors.administrative_name = "El nombre administrativo no debe exceder los 50 caracteres.";
        }
        if (providerForm.administrative_phone && providerForm.administrative_phone.length > 36) {
            errors.administrative_phone = "El teléfono administrativo no debe exceder los 36 caracteres.";
        }
        if (providerForm.administrative_email && providerForm.administrative_email.length > 36) {
            errors.administrative_email = "El email administrativo no debe exceder los 36 caracteres.";
        }
        if (providerForm.administrative_position && providerForm.administrative_position.length > 36) {
            errors.administrative_position = "La posición administrativa no debe exceder los 36 caracteres.";
        }
        if (providerForm.sales_representative_name && providerForm.sales_representative_name.length > 50) {
            errors.sales_representative_name = "El nombre del representante de ventas no debe exceder los 50 caracteres.";
        }
        if (providerForm.sales_representative_phone && providerForm.sales_representative_phone.length > 36) {
            errors.sales_representative_phone = "El teléfono del representante de ventas no debe exceder los 36 caracteres.";
        }
        if (providerForm.sales_representative_email && providerForm.sales_representative_email.length > 36) {
            errors.sales_representative_email = "El email del representante de ventas no debe exceder los 36 caracteres.";
        }
        if (providerForm.sales_representative_position && providerForm.sales_representative_position.length > 36) {
            errors.sales_representative_position = "La posición del representante de ventas no debe exceder los 36 caracteres.";
        }

        return errors;
    };

    const handleUpdate = async (e) => {

        let error = null;

        try {
            e.preventDefault();
            setLoading(prev => ({ ...prev, update: true }));

            const validationError = validateForm();
            setError(validationError);

            const result = await api().patch(`/providers/`, providerForm);
            const provider = result.data

            setProviderForm(provider);
            showSnackbar('Proveedor actualizado correctamente');

        } catch (e) {
            error = handleApiError(e, 'Error inesperado actualizando proveedor.')
        } finally {
            setError(prevError => ({ ...prevError, update: error }));
            setLoading(prev => ({ ...prev, update: false }));
        }
    };

    const handleOpenDisableDialog = (itemId, endpoint, navigateTo) => {
        setDisableId(itemId);
        setEndpointToDisable(endpoint);
        setNavigateToWhenDisabled(navigateTo);
        setOpenDisableDialog(true);
    };

    return (
        <div style={{ padding: 20 }}>
            {loading?.general && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading?.general && (
                <div>
                    <Grid container spacing={3} style={{ position: 'fixed', top: 60, right: 10, zIndex: 1100, width: 'auto' }}>
                        <Grid item>
                            <Paper elevation={0} style={{ padding: '10px', textAlign: 'center', backgroundColor: '#fff' }}>
                                <Box mb={2}>
                                    <Typography variant="h5" component="h1" gutterBottom>
                                        {providerForm.code} - {providerForm.name}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={10} lg={8}>
                        <Accordion title={`${providerForm.code} ${providerForm.name}`}>
                            <form onSubmit={handleUpdate} style={{ padding: '20px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Información Principal</Typography>
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Nombre"
                                            name="name"
                                            value={providerForm.name}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 36 }}
                                            error={!!error?.name}
                                            helperText={error?.name}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            name="email"
                                            value={providerForm.email}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 36 }}
                                            error={!!error?.email}
                                            helperText={error?.email}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Teléfono"
                                            name="phone"
                                            value={providerForm.phone}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 36 }}
                                            error={!!error?.phone}
                                            helperText={error?.phone}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Nombre comercial (alias)"
                                            name="commercial_name"
                                            value={providerForm.commercial_name}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 36 }}
                                            error={!!error?.commercial_name}
                                            helperText={error?.commercial_name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                                        <Typography variant="h6">Documentación</Typography>
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="CIF"
                                            name="cif"
                                            value={providerForm.cif}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 9 }}
                                            error={!!error?.cif}
                                            helperText={error?.cif}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                                        <Typography variant="h6">Dirección</Typography>
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="País"
                                            name="country"
                                            value={providerForm.country}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 36 }}
                                            error={!!error?.country}
                                            helperText={error?.country}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Localidad"
                                            name="region"
                                            value={providerForm.region}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 150 }}
                                            error={!!error?.region}
                                            helperText={error?.region}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Provincia"
                                            name="province"
                                            value={providerForm.province}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 150 }}
                                            error={!!error?.province}
                                            helperText={error?.province}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Código postal"
                                            name="zip_code"
                                            value={providerForm.zip_code}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 9 }}
                                            error={!!error?.zip_code}
                                            helperText={error?.zip_code}
                                        />
                                    </Grid>

                                    <Grid item xs={10} md={4}>
                                        <TextField
                                            fullWidth
                                            label="Dirección"
                                            name="address"
                                            value={providerForm.address}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 150 }}
                                            error={!!error?.address}
                                            helperText={error?.address}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                                        <Typography variant="h6">Contactos</Typography>
                                    </Grid>
                                    <Grid container item spacing={2}>

                                        <Grid item xs={10} md={2}>
                                            <TextField
                                                fullWidth
                                                label="Nombre Administrativo"
                                                name="administrative_name"
                                                value={providerForm.administrative_name}
                                                onChange={handleChangeForm}
                                                inputProps={{ maxLength: 50 }}
                                                error={!!error?.administrative_name}
                                                helperText={error?.administrative_name}
                                            />
                                        </Grid>
                                        <Grid item xs={10} md={2}>
                                            <TextField
                                                fullWidth
                                                label="Teléfono Administrativo"
                                                name="administrative_phone"
                                                value={providerForm.administrative_phone}
                                                onChange={handleChangeForm}
                                                inputProps={{ maxLength: 36 }}
                                                error={!!error?.administrative_phone}
                                                helperText={error?.administrative_phone}
                                            />
                                        </Grid>
                                        <Grid item xs={10} md={2}>
                                            <TextField
                                                fullWidth
                                                label="Email Administrativo"
                                                name="administrative_email"
                                                value={providerForm.administrative_email}
                                                onChange={handleChangeForm}
                                                inputProps={{ maxLength: 36 }}
                                                error={!!error?.administrative_email}
                                                helperText={error?.administrative_email}
                                            />
                                        </Grid>
                                        <Grid item xs={10} md={2}>
                                            <TextField
                                                fullWidth
                                                label="Posición Administrativo"
                                                name="administrative_position"
                                                value={providerForm.administrative_position}
                                                onChange={handleChangeForm}
                                                inputProps={{ maxLength: 36 }}
                                                error={!!error?.administrative_position}
                                                helperText={error?.administrative_position}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={2}>
                                        <Grid item xs={10} md={2}>
                                            <TextField
                                                fullWidth
                                                label="Nombre Representante de Ventas"
                                                name="sales_representative_name"
                                                value={providerForm.sales_representative_name}
                                                onChange={handleChangeForm}
                                                inputProps={{ maxLength: 50 }}
                                                error={!!error?.sales_representative_name}
                                                helperText={error?.sales_representative_name}
                                            />
                                        </Grid>
                                        <Grid item xs={10} md={2}>
                                            <TextField
                                                fullWidth
                                                label="Teléfono Representante de Ventas"
                                                name="sales_representative_phone"
                                                value={providerForm.sales_representative_phone}
                                                onChange={handleChangeForm}
                                                inputProps={{ maxLength: 36 }}
                                                error={!!error?.sales_representative_phone}
                                                helperText={error?.sales_representative_phone}
                                            />
                                        </Grid>
                                        <Grid item xs={10} md={2}>
                                            <TextField
                                                fullWidth
                                                label="Email Representante de Ventas"
                                                name="sales_representative_email"
                                                value={providerForm.sales_representative_email}
                                                onChange={handleChangeForm}
                                                inputProps={{ maxLength: 36 }}
                                                error={!!error?.sales_representative_email}
                                                helperText={error?.sales_representative_email}
                                            />
                                        </Grid>
                                        <Grid item xs={10} md={2}>
                                            <TextField
                                                fullWidth
                                                label="Posición Representante de Ventas"
                                                name="sales_representative_position"
                                                value={providerForm.sales_representative_position}
                                                onChange={handleChangeForm}
                                                inputProps={{ maxLength: 36 }}
                                                error={!!error?.sales_representative_position}
                                                helperText={error?.sales_representative_position}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleOpenDisableDialog(id, '/providers/disable', '/providers/list')}
                                            fullWidth
                                            style={{ backgroundColor: 'red' }}>
                                            Eliminar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={loading?.update}
                                            fullWidth>
                                            {loading?.update ? (
                                                <CircularProgress size={24} color="inherit" />
                                            ) : (
                                                'Actualizar'
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                                {error.update && (
                                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                        {error.update}
                                    </Typography>
                                )}
                            </form>
                        </Accordion>
                        <Accordion title="Documentos">
                            <UploadDocumentButton
                                to={'provider_id'}
                                id={id}
                                fetch={fetchProvider}>
                            </UploadDocumentButton>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nombre</TableCell>
                                            <TableCell>Acciones</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {providerForm.documents.filter(item => !item.disabled).map(item => (
                                            <TableRow key={item.id}>
                                                <TableCell>
                                                    <DownloadDocumentButton documentId={item.id} documentName={item.name} />
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="outlined"
                                                        color="secondary"
                                                        onClick={() => handleOpenDisableDialog(item.id, '/documents/disable')}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Accordion>
                        <Accordion title="Pedidos">
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Código</TableCell>
                                            <TableCell>Fecha</TableCell>
                                            <TableCell>Precio Total</TableCell>
                                            <TableCell>Estado</TableCell>
                                            <TableCell>Acciones</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orders?.filter(order => !order.disabled).map(order => (
                                            <TableRow key={order.id}>
                                                <TableCell>
                                                    <Link to={`/provider_orders/${order.id}/details`}>
                                                        {order.code}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>{formatDate(order.provider_order_date)}</TableCell>
                                                <TableCell>{formatPrice(order.total_price)}</TableCell>
                                                <TableCell>{formatOrderStatus(order.provider_order_status)}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="outlined"
                                                        color="secondary"
                                                        onClick={() => handleOpenDisableDialog(order.id, '/provider_orders/disable')}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Accordion>
                        <Accordion title="Albaranes">
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Código</TableCell>
                                            <TableCell>Facturado</TableCell>
                                            <TableCell>Fecha</TableCell>
                                            <TableCell>Acciones</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {deliveryNotes?.filter(item => !item.disabled).map(item => (
                                            <TableRow key={item.id}>
                                                <TableCell>
                                                    <Link to={`/provider_delivery_notes/${item.id}/details`}>
                                                        {item.code || '-'}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>{item.invoiced ? "Sí" : "No"}</TableCell>
                                                <TableCell>{formatDate(item.dn_date)}</TableCell>
                                                <TableCell>
                                                    {item.documents && item.documents.length > 0 && (() => {
                                                        const activeDocument = item.documents.find(doc => !doc.disabled);
                                                        return activeDocument ? (
                                                            <DownloadDocumentButton documentId={activeDocument.id} documentName={activeDocument.name} />
                                                        ) : null;
                                                    })()}
                                                    <UploadDocumentButton
                                                        to={'delivery_note_id'}
                                                        id={item.id}
                                                        fetch={fetchProvider}
                                                    >
                                                    </UploadDocumentButton>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => handleOpenDisableDialog(
                                                            item.id,
                                                            '/delivery_note_providers/disable')}
                                                        sx={{ ml: 2 }}
                                                    >
                                                        Borrar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Accordion>
                        {/* Invoices Accordion */}
                        <InvoiceTable
                            isProviderInvoice={true}
                            showBuilding={false}
                            showClientOrProvider={false}
                            title='Facturas'
                            totalAmount={totalPriceSum}
                            paidAmount={paidAmountSum}
                            remainingAmountSum={remainingAmountSum}
                            invoices={invoices}
                            uploadDocument={true}
                            fetch={fetchProvider}
                            handleOpenDeleteDialog={handleOpenDisableDialog}
                        />
                        <DisableDialog
                            open={openDisableDialog}
                            onClose={() => setOpenDisableDialog(false)}
                            endpoint={endpointToDisable}
                            disableId={disableId}
                            navigateTo={navigateToWhenDisabled}
                        />
                    </Grid>
                </div>)}
        </div>

    );
}

export default ProviderDetails;
