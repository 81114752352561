import React from "react";
import { TextField, Button, Grid, MenuItem, FormControl, InputLabel, Select, IconButton, Typography } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { DecimalTextField, DatePickerComponent } from '../../components/Utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import "dayjs/locale/es";

dayjs.extend(utc);

const ProductCosts = ({ costs, setCosts, title = 'Gastos del producto' }) => {
    const handleInputChange = (index, field, value) => {
        const updatedCosts = [...costs];
        updatedCosts[index][field] = value;
        setCosts(updatedCosts);
    };

    const handleAddLine = () => {
        setCosts([...costs, { name: "", date_created: dayjs().utc().format('YYYY-MM-DD'), quantity: "", unit_price: "", unit: "uds" }]);
    };

    const handleDeleteLine = (index) => {
        const updatedCosts = costs.filter((_, i) => i !== index);
        setCosts(updatedCosts);
    };

    const handleDateChange = (index, newValue) => {
        let date_created = dayjs(newValue).hour(12).minute(0).second(0);
        date_created = date_created.utc().format('YYYY-MM-DD');
    
        setCosts((prevCosts) =>
            prevCosts.map((cost, i) =>
                i === index ? { ...cost, date_created } : cost
            )
        );
    };

    return (
        <div>
            <Typography variant="h6" gutterBottom>
                {title}
            </Typography>
            {costs.map((cost, index) => (
                <Grid container spacing={2} alignItems="center" key={index} style={{ marginBottom: '24px' }}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Nombre del gasto"
                            value={cost.name}
                            onChange={(e) => handleInputChange(index, "name", e.target.value)}
                            fullWidth
                            InputProps={{ style: { fontSize: 16, height: '56px' } }}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <DecimalTextField
                            label="Cantidad"
                            name="quantity"
                            value={cost.quantity}
                            onChange={(e) => handleInputChange(index, "quantity", e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <DecimalTextField
                            label="Precio unitario"
                            name="unit_price"
                            value={cost.unit_price}
                            onChange={(e) => handleInputChange(index, "unit_price", e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <FormControl fullWidth>
                            <InputLabel id="unit-label">Unidades</InputLabel>
                            <Select
                                labelId="unit-label"
                                id="unit"
                                label="Unidades"
                                value={cost.unit}
                                onChange={(e) => handleInputChange(index, "unit", e.target.value)}
                                name="unit"
                                inputProps={{ id: 'unit' }}
                            >
                                <MenuItem value="uds">UDS</MenuItem>
                                <MenuItem value="Tn">Tn</MenuItem>
                                <MenuItem value="kg">KG</MenuItem>
                                <MenuItem value="l">L</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <DatePickerComponent
                            label="Fecha"
                            value={cost.date_created}
                            onChange={(newValue) => handleDateChange(index, newValue)}
                        />
                    </Grid>
                    <Grid item xs={12} md={0.5}>
                        <IconButton onClick={() => handleDeleteLine(index)} color="secondary">
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}

            <Grid container justifyContent="flex-start" style={{ marginTop: 16 }}>
                <Button variant="contained" color="primary" onClick={handleAddLine}>
                    Añadir Gasto
                </Button>
            </Grid>
        </div>
    );
};
export default ProductCosts;
